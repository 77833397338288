/**
 *
 * Asynchronously loads the component for Banner
 *
 */

import { lazyLoad } from 'utils/loadable';

export const UcoJerryCanRequest = lazyLoad(
    () => import('./index'),
    module => module.UcoJerryCanRequest,
);

export const UcoJerryCanEditData = lazyLoad(
    () => import('./edit-data'),
    module => module.UcoJerryCanEditData,
);

export const UcoJerryCanEditDeposit = lazyLoad(
    () => import('./edit-deposit'),
    module => module.UcoJerryCanEditDeposit,
);
