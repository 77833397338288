export const BALANCE_CARD_TITLE: string = 'BALANCE';
export const BANK_STATEMENT_DOWNLOAD_BUTTON: string = 'Download Bank Statment';
export const RUPIAH_CURRENCY: string = 'Rp.';
export const REFRESH_BUTTON: string = 'Refresh';
export const WITHDRAW_TRANSACTION_AMOUNT: string = 'WITHDRAW TRANSACTION';
export const TRANSACTION: string = 'transaksi';
export const SEARCH_INPUT_PLACEHOLDER: string = 'Cari berdasarkan nama atau ID';
export const TRANSFER_BUTTON: string = 'Transfer';
export const FAILED_BUTTON: string = 'Failed';
export const COMPLETED_BUTTON: string = 'Completed';
export const LAST_UPDATED: string = 'Latest Update';
export const TOP_UP_BUTTON: string = 'Top Up';
export const DOWNLOAD_BUTTON: string = 'Download';
export const DOWNLOAD_BANK_STATEMENT_MODAL_TITLE: string =
  'Download Bank Statement';

export const DOWNLOAD_BANK_STATEMENT_MODAL_BUTTON: string = 'Download';
export const PENDING_TOOLTIP_TITLE: string = 'Indicate Fraud';
export const PENDING_TOOLTIP_DESC: string =
  'This transaction is above the threshold or seems unusual. Review to processing.';
export const REVIEW_PENDING_BUTTON: string = 'Review';

export const CURRENT_BALANCE: string = 'Current Balance';
export const BALANCE_AFTER_TOP_UP: string = 'Balance After Top Up ';
