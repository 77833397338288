import { ApiResponse } from 'apisauce';
import { GeneralDataResponse } from './api.types';

export type GeneralApiProblem =
  /**
   * Times up.
   */
  | { kind: 'timeout'; temporary: true; response?: GeneralDataResponse }
  /**
   * Cannot connect to the server for some reason.
   */
  | { kind: 'cannot-connect'; temporary: true; response?: GeneralDataResponse }
  /**
   * The server experienced a problem. Any 5xx error.
   */
  | { kind: 'server'; response?: GeneralDataResponse }
  /**
   * We're not allowed because we haven't identified ourself. This is 401.
   */
  | { kind: 'unauthorized'; response?: GeneralDataResponse }
  /**
   * We don't have access to perform that request. This is 403.
   */
  | { kind: 'forbidden'; response?: GeneralDataResponse }
  /**
   * Unable to find that resource.  This is a 404.
   */
  | { kind: 'not-found'; response?: GeneralDataResponse }
  /**
   * All other 4xx series errors.
   */
  | { kind: 'rejected'; response?: GeneralDataResponse }
  /**
   * Something truly unexpected happened. Most likely can try again. This is a catch all.
   */
  | { kind: 'unknown'; temporary: true; response?: GeneralDataResponse }
  /**
   * The data we received is not in the expected format.
   */
  | { kind: 'bad-data'; response?: GeneralDataResponse };

/**
 * Attempts to get a common cause of problems from an api response.
 *
 * @param response The api response.
 */
export function getGeneralApiProblem(
  response: ApiResponse<any>,
): GeneralApiProblem | void | null {
  switch (response.problem) {
    case 'CONNECTION_ERROR':
      return {
        kind: 'cannot-connect',
        temporary: true,
        response: response.data,
      };
    case 'NETWORK_ERROR':
      return {
        kind: 'cannot-connect',
        temporary: true,
        response: response.data,
      };
    case 'TIMEOUT_ERROR':
      return { kind: 'timeout', temporary: true, response: response.data };
    case 'SERVER_ERROR':
      return { kind: 'server', response: response.data };
    case 'UNKNOWN_ERROR':
      return { kind: 'unknown', temporary: true, response: response.data };
    case 'CLIENT_ERROR':
      switch (response.status) {
        case 400:
          return { kind: 'unauthorized', response: response.data };
        case 401:
          return { kind: 'unauthorized', response: response.data };
        case 403:
          return { kind: 'forbidden', response: response.data };
        case 404:
          return { kind: 'not-found', response: response.data };
        default:
          return { kind: 'rejected', response: response.data };
      }
    case 'CANCEL_ERROR':
      return null;
  }
  return null;
}
