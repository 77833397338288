import React from 'react';
import { VStack } from '../VStack';
import packageJson from '../../../../package.json';

export const Version = () => (
  <VStack className={'p-5'}>
    <label htmlFor="email" className="block text-sm font-medium text-white">
      {`V ${packageJson.version}`}
    </label>
  </VStack>
);
