import * as React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { GlobalStyle } from '../styles/global-styles';
import { NotFoundPage } from './pages/NotFound';
import { useTranslation } from 'react-i18next';
import useToken from '../hooks/useToken';
import { useApiSlice } from '../api/slice';
import { useDispatch, useSelector } from 'react-redux';
import { Dashboard } from './pages/Dashboard/Loadable';
import {
  DropStation,
  DropStationDetail,
  DropStationForm,
} from './pages/DropStation/Loadable';
import {
  DropIn,
  DropInDetail,
  DropInFormSort,
  DropInFormReceive,
} from './pages/DropIn/Loadable';
import {
  UcoPickupRequest,
  UcoPickupEditData,
  UcoPickupEditTransfer,
} from './pages/UcoPickup/Loadable';
import { UcoClient, UcoClientDetail } from './pages/UcoClient/Loadable';
import {
  UcoJerryCanRequest,
  UcoJerryCanEditData,
  UcoJerryCanEditDeposit,
} from './pages/UcoJerryCan/Loadable';
import {
  UcoRoutinePickup,
  UcoRoutinePickupEditData,
  UcoRoutinePickupEditTransfer,
} from './pages/UcoRoutinePickup/Loadable';
import { collection, onSnapshot, where, query } from 'firebase/firestore';
import { WasteCategoryGroupType } from '../types/DropIn';
import db from '../firebase';
import { useDropInSlice } from './modules/dropIn/slice';
import { useDropStationSlice } from './modules/dropStation/slice';
import { convertFirebaseData } from '../utils/convert-firebase-data';
import { Reboxes, ReboxForm, ReboxDetail } from './pages/Rebox/Loadable';
import {
  ProductList,
  ProductDetail,
  ProductForm,
} from './pages/Product/Loadable';
import { Withdraw, WithdrawMethod } from './pages/Withdraw/Loadable';
import { Banner } from './pages/Banner';
import { BannerDetail } from './pages/Banner/details';
import { BannerForm } from './pages/Banner/form';
import { UcoSetting, UcoReminderSetting } from './pages/UcoSetting/Loadable';
import { AccountList, EditAccount } from './pages/AccountManager/Loadable';
import { selectProfileDetail } from './modules/profile/slice/selectors';
import { PROFILE_ID_KEY, ROLE_ID_KEY } from '../api/api.types';
import { useAuthSlice } from './modules/profile/slice';
import {
  ACCOUNT_MANAGER_PERMITTED_ROLE,
  BANNER_PERMITTED_ROLE,
  DROP_IN_PERMITTED_ROLE,
  DROP_POINTS_PERMITTED_ROLE,
  FRAUD_PERMITTED_ROLE,
  REBOX_PERMITTED_ROLE,
  THRESHOLD_PERMITTED_ROLE,
  UCO_PERMITTED_ROLE,
  WITHDRAW_PERMITTED_ROLE,
} from '../config/global.config';
import { AccountListType } from '../types/User';
import { getAuth, signOut } from 'firebase/auth';
import { selectApi } from '../api/slice/selectors';
import { Login } from './pages/Login/login';
import {
  FraudManagement,
  FraudManagementDetail,
} from './pages/FraudManagement/Loadable';
import {
  ThresholdForm,
  ThresholdSetting,
} from './pages/ThresholdSetting/loadable';

export function App() {
  const { i18n } = useTranslation();
  const { token } = useToken();

  const profileDetail = useSelector(selectProfileDetail);
  const api = useSelector(selectApi);

  const { actionApi } = useApiSlice();
  const { actionsDropStation } = useDropStationSlice();
  const { actionsDropIn } = useDropInSlice();
  const { actions } = useAuthSlice();

  const dispatch = useDispatch();

  const getProfile = () => {
    dispatch(
      actions.setProfileId(Number(localStorage.getItem(PROFILE_ID_KEY) ?? '0')),
    );
    dispatch(actions.loadProfileDetail());
  };

  const setRoleToLocal = (profile: AccountListType) => {
    if (profile.id === Number(localStorage.getItem(PROFILE_ID_KEY)))
      localStorage.setItem(ROLE_ID_KEY, profile.roleId.toString());
  };

  const getLocalRoleId = () => {
    return Number(localStorage.getItem(ROLE_ID_KEY ?? '0'));
  };

  // Handle route by role
  const RoleRoute = ({ roles, ...props }) => {
    return !roles.length || roles.includes(getLocalRoleId()) ? (
      <Route {...props} />
    ) : (
      <Redirect to="/" />
    );
  };

  const logout = async () => {
    dispatch(actionApi.clearToken());
    api.removeToken();
    const auth = getAuth();
    await signOut(auth);
  };

  useEffect(() => {
    dispatch(actionApi.loadToken());
    if (token) {
      getProfile();
      dispatch(actionsDropStation.loadDropStationList());
    }
  }, [token]);

  useEffect(() => {
    setRoleToLocal(profileDetail);
  }, [profileDetail]);

  useEffect(() => {
    // Get Waste Category Groups
    const q = query(
      collection(db, 'wasteCategoryGroups'),
      where('order', '>', 0),
    );
    const getWasteCategoryGroups = onSnapshot(q, snapshot => {
      const arr: WasteCategoryGroupType[] = [];
      snapshot.forEach(async a => {
        const b: WasteCategoryGroupType = convertFirebaseData(a.data());
        b.id = a.id;
        arr.push(b);
      });
      dispatch(actionsDropIn.setWasteCategoryGroups(arr));
    });

    // Stop listening to changes
    return () => {
      getWasteCategoryGroups();
    };
  }, []);

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Rekosistem"
        defaultTitle="Rekosistem"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Rekosistem" />
      </Helmet>

      <Switch>
        {/* navigation reverted to this file */}
        <Route
          exact
          path={process.env.PUBLIC_URL + '/login'}
          component={() => (token ? <Redirect to="/" /> : <Login />)}
        />
        <Route
          exact
          path={process.env.PUBLIC_URL + '/logout'}
          component={() => {
            logout().then(() => window.location.replace('/login'));
            return <></>;
          }}
        />
        {token ? (
          <Switch>
            <Route
              exact
              path={process.env.PUBLIC_URL + '/'}
              component={() => <Dashboard />}
            />
            {/* Drop In Route */}
            <RoleRoute
              roles={DROP_IN_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/drop-in'}
              component={() => <DropIn title={'Drop-In Overview'} />}
            />
            <RoleRoute
              roles={DROP_IN_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/drop-in/create'}
              component={() => <DropInFormSort />}
            />
            <RoleRoute
              roles={DROP_IN_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/drop-in/create'}
              component={() => <DropInFormSort />}
            />
            <RoleRoute
              roles={DROP_IN_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/drop-in/sort/:id/edit'}
              component={() => <DropInFormSort />}
            />
            <RoleRoute
              roles={DROP_IN_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/drop-in/sort'}
              component={() => <DropInFormSort />}
            />
            <RoleRoute
              roles={DROP_IN_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/drop-in/receive'}
              component={() => <DropInFormReceive />}
            />
            <RoleRoute
              roles={DROP_IN_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/drop-in/receive/:id/edit'}
              component={() => <DropInFormReceive />}
            />
            <RoleRoute
              roles={DROP_IN_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/drop-in/receive/:id/edit'}
              component={() => <DropInFormReceive />}
            />
            <RoleRoute
              roles={DROP_IN_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/drop-in/detail/:id'}
              component={() => <DropInDetail title={'DropIn Detail'} />}
            />

            {/* Drop Station Route */}
            <RoleRoute
              roles={DROP_POINTS_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/drop-points'}
              component={() => <DropStation title={'Drop Point'} />}
            />
            <RoleRoute
              roles={DROP_POINTS_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/drop-point/create'}
              component={() => <DropStationForm />}
            />
            <RoleRoute
              roles={DROP_POINTS_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/drop-point/:id/edit'}
              component={() => <DropStationForm />}
            />
            <RoleRoute
              roles={DROP_POINTS_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/drop-point/detail/:id'}
              component={() => (
                <DropStationDetail title={'Drop Point Detail'} />
              )}
            />

            {/* Banner Route */}
            <RoleRoute
              roles={BANNER_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/banner-list'}
              component={() => <Banner title={'Banner List'} />}
            />
            <RoleRoute
              roles={BANNER_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/banner/detail/:id'}
              component={() => <BannerDetail title={'Banner Detail'} />}
            />
            <RoleRoute
              roles={BANNER_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/banner/create'}
              component={() => <BannerForm />}
            />
            <RoleRoute
              roles={BANNER_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/banner/:id/edit'}
              component={() => <BannerForm />}
            />

            {/* Rebox Route */}
            <RoleRoute
              roles={REBOX_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/rebox-list'}
              component={() => <Reboxes title={'Rebox List'} />}
            />
            <RoleRoute
              roles={REBOX_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/rebox/detail/:id'}
              component={() => <ReboxDetail title={'Rebox Detail'} />}
            />
            <RoleRoute
              roles={REBOX_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/rebox/create'}
              component={() => <ReboxForm />}
            />
            <RoleRoute
              roles={REBOX_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/rebox/:id/edit'}
              component={() => <ReboxForm />}
            />
            <RoleRoute
              roles={REBOX_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/rebox-products'}
              component={() => <ProductList title={'Rebox Product List'} />}
            />
            <RoleRoute
              roles={REBOX_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/rebox-product/detail/:id'}
              component={() => <ProductDetail title={'Product Detail'} />}
            />
            <RoleRoute
              roles={REBOX_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/rebox-product/create'}
              component={() => <ProductForm />}
            />
            <RoleRoute
              roles={REBOX_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/rebox-product/:id/edit'}
              component={() => <ProductForm />}
            />

            {/* Withdraw Route */}
            <RoleRoute
              roles={WITHDRAW_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/withdraws'}
              component={() => <Withdraw title={'Withdraw Management'} />}
            />
            <RoleRoute
              roles={WITHDRAW_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/withdraw-method/:id'}
              component={() => <WithdrawMethod title={'Withdraw Management'} />}
            />

            <RoleRoute
              roles={FRAUD_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/fraud-management'}
              component={() => <FraudManagement title={'Fraud Management'} />}
            />
            <RoleRoute
              roles={FRAUD_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/fraud-management/:fraudId'}
              component={() => (
                <FraudManagementDetail title={'Fraud Management'} />
              )}
            />
            <RoleRoute
              roles={THRESHOLD_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/threshold-setting'}
              component={() => <ThresholdSetting title={'Threshold Setting'} />}
            />
            <RoleRoute
              roles={THRESHOLD_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/threshold-setting/:method'}
              component={() => <ThresholdForm title={'Threshold Setting'} />}
            />

            {/* UCO Route */}
            <RoleRoute
              roles={UCO_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/uco-pickup'}
              component={() => (
                <UcoPickupRequest
                  title={'UCO One Time Pick-up Request Review Table'}
                />
              )}
            />
            <RoleRoute
              roles={UCO_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/uco-pickup/edit-data/:id'}
              component={() => (
                <UcoPickupEditData title={'UCO Pick-up\n' + 'Edit Data'} />
              )}
            />
            <RoleRoute
              roles={UCO_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/uco-pickup/edit-transfer/:id'}
              component={() => (
                <UcoPickupEditTransfer title={'UCO Pick-up Edit Transfer'} />
              )}
            />
            <RoleRoute
              roles={UCO_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/uco-jerry-can'}
              component={() => (
                <UcoJerryCanRequest title={'Jerry-can Request Review Table'} />
              )}
            />
            <RoleRoute
              roles={UCO_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/uco-jerry-can/edit-data/:id'}
              component={() => (
                <UcoJerryCanEditData
                  title={'Jerry-can Request\n' + 'Edit Data'}
                />
              )}
            />
            <RoleRoute
              roles={UCO_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/uco-jerry-can/edit-deposit/:id'}
              component={() => (
                <UcoJerryCanEditDeposit
                  title={'Jerry-can Request\n' + 'Edit Order'}
                />
              )}
            />
            <RoleRoute
              roles={UCO_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/uco-setting'}
              component={() => <UcoSetting title={'PWA Setting'} />}
            />
            <RoleRoute
              roles={UCO_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/uco-setting-reminder'}
              component={() => (
                <UcoReminderSetting title={'Setup Reminder Internal'} />
              )}
            />
            <RoleRoute
              roles={UCO_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/uco-client'}
              component={() => <UcoClient title={'UCO Pick-up Client List'} />}
            />
            <RoleRoute
              roles={UCO_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/uco-add-client'}
              component={() => (
                <UcoClientDetail
                  title={'UCO Pick-up Client List'}
                  edit={false}
                />
              )}
            />
            <RoleRoute
              roles={UCO_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/uco-client-detail/:type/:id'}
              component={() => (
                <UcoClientDetail
                  title={'UCO Pick-up Client List'}
                  edit={true}
                />
              )}
            />
            <RoleRoute
              roles={UCO_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/uco-routine-pickup'}
              component={() => (
                <UcoRoutinePickup title={'Routine Pick-up Request Overview'} />
              )}
            />
            <RoleRoute
              roles={UCO_PERMITTED_ROLE}
              exact
              path={
                process.env.PUBLIC_URL + '/uco-routine-pickup/edit-data/:id'
              }
              component={() => (
                <UcoRoutinePickupEditData
                  title={'UCO Routine Pickup Edit Data'}
                />
              )}
            />
            <RoleRoute
              roles={UCO_PERMITTED_ROLE}
              exact
              path={
                process.env.PUBLIC_URL + '/uco-routine-pickup/edit-transfer/:id'
              }
              component={() => (
                <UcoRoutinePickupEditTransfer
                  title={'UCO Routine Pickup Edit Transfer'}
                />
              )}
            />

            {/* Account Management */}
            <RoleRoute
              roles={ACCOUNT_MANAGER_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/account-list'}
              component={() => <AccountList title={'Account List'} />}
            />
            <RoleRoute
              roles={ACCOUNT_MANAGER_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/account-list/edit-account/:id'}
              component={() => <EditAccount title={'Edit account'} />}
            />
            <RoleRoute
              roles={ACCOUNT_MANAGER_PERMITTED_ROLE}
              exact
              path={process.env.PUBLIC_URL + '/account-list/add-account'}
              component={() => <EditAccount title={'Add account'} />}
            />
            {/* / */}
            <Route path={'*'} component={() => <NotFoundPage />} />
          </Switch>
        ) : (
          <Redirect to="/login" />
        )}
      </Switch>
      <GlobalStyle />
    </BrowserRouter>
  );
}
