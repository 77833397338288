export enum Constants {
  APP_NAME = 'Rekosistem',
  STATUS_PENDING = 'pending',
  STATUS_RECEIVED = 'received',
  STATUS_SORTING = 'sorting',
  STATUS_SORTED = 'sorted',
  EVIDENCE = 'evidence',
  STATUS_DELETED = 'deleted',
  STATUS_CANCEL = 'cancel',
}

export enum StatusCodeHttp {
  UNAUTHORIZED = 401,
  SUCCESS = 200,
  NOT_FOUND = 404,
}

export const WITHDRAW_CARD_AVAILABLE: string = 'Available Balance';
export const WITHDRAW_CARD_CURRENT: string = 'Current Balance';
export const WITHDRAW_CARD_MANAGE: string = 'Manage';
export const WITHDRAW_CARD_TOP_UP: string = 'Top-Up';
export const WITHDRAW_CARD_TRANSFER: string = 'Transfer';
export const WITHDRAW_METHOD_SORT_BY_TRANSACTION: string =
  'Sort by Transaction Type';
export const WITHDRAW_METHOD_TITLE: string = 'History';

export const AMOUNT_TOP_UP_COLOR: string = ' text-[#6ABAAE]';
export const AMOUNT_TRANSFER_COLOR: string = ' text-[#E35B5B]';
export const EMPTY_STRING: string = '';
export const DASH_STRING: string = '-';
export const SAVE: string = 'Simpan';
