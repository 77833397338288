import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';
import { classNames } from '../../../utils/classNames';
import * as React from 'react';
import { IFloatingDropDownProps } from './floating-dropdown.props';

const NO_ACTION: string = 'No Action';

export const FloatingDropdown: React.FC<IFloatingDropDownProps> = props => {
  const {
    dropdownTitle,
    disable = false,
    options,
    showChevronDown = false,
    buttonText,
    onClick,
  } = props;

  return (
    <Menu as="div" className="flex-shrink-0 pr-2">
      <Menu.Button
        disabled={disable}
        className="inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
      >
        <div className="flex flex-row">
          <div className={'whitespace text-sm text-gray-500'}>{buttonText}</div>
          {showChevronDown ? (
            <ChevronDownIcon className="ml-2 w-5 h-5" aria-hidden="true" />
          ) : null}
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-10 mx-3 origin-top-right absolute right-10 top-3 w-48 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
          <div className="py-1">
            <span className="font-normal text-sm text-neutral-90 mb-4">
              {dropdownTitle}
            </span>
            {options.length
              ? options.map((item, index) => {
                  return (
                    <Menu.Item
                      key={index.toString() + item.optionText}
                      onClick={() => onClick(item)}
                    >
                      {({ active }) => (
                        <button
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full',
                          )}
                        >
                          {item.optionText}
                        </button>
                      )}
                    </Menu.Item>
                  );
                })
              : NO_ACTION}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
