import React from 'react';
import { ICardDetailProps } from './card-detail.props';

export const CardDetail: React.FC<ICardDetailProps> = props => {
  const isDisable = props.disabled ?? false;
  const CONTENT_DISABLED =
    'pointer-events-none bg-slate-300 shadow overflow-hidden sm:rounded-lg mb-4 px-11 py-6';
  const CONTENT_ACTIVE =
    'bg-white shadow overflow-hidden sm:rounded-lg mb-4 px-11 py-6';

  return (
    <div className={isDisable ? CONTENT_DISABLED : CONTENT_ACTIVE}>
      <h3 className="text-lg leading-6 text-gray-900">{props.title}</h3>
      <span className="mx-2" />
      {props.content}
    </div>
  );
};
