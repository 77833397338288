// @ts-nocheck
import { ApiResponse } from 'apisauce';
import { getGeneralApiProblem } from './api-problem';
import { logger } from 'utils/logger';
import {
  REGENCY,
  VILLAGE,
  DISTRICT,
  GeneralApiResult,
  GeneralDataResponse,
  PROVINCE,
  VOUCHER_LIST,
} from './api.types';
import { Api } from './api';

/**
 * Logger
 */
const log = logger().child({ module: 'ApiCustomer' });

/**
 * Manages all requests to the API.
 */
export class ApiFormData {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getVoucherList(dropCode: string): Promise<GeneralApiResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.get(
        VOUCHER_LIST + (dropCode ? '?dropCode=' + dropCode : ''),
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const generalResponse: GeneralDataResponse = response.data;

      if (response.ok) {
        try {
          return { kind: 'ok', data: generalResponse };
        } catch {
          return { kind: 'bad-data' };
        }
      }
    } catch (e) {
      log.error(e);
      return { kind: 'bad-data' };
    }
  }

  async getProvinceList(keyword: string): Promise<GeneralApiResult> {
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(
        PROVINCE,
        { keyword },
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const generalResponse: GeneralDataResponse = response.data;

      if (response.ok) {
        try {
          return { kind: 'ok', data: generalResponse };
        } catch {
          return { kind: 'bad-data' };
        }
      }
    } catch (e) {
      log.error(e);
      return { kind: 'bad-data' };
    }
  }

  async getRegencyList(
    provinceId: number,
    keyword: string,
  ): Promise<GeneralApiResult> {
    if (provinceId < 0) return { kind: 'rejected' };
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(REGENCY, {
        provinceId,
        keyword,
      });

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const generalResponse: GeneralDataResponse = response.data;

      if (response.ok) {
        try {
          return { kind: 'ok', data: generalResponse };
        } catch {
          return { kind: 'bad-data' };
        }
      }
    } catch (e) {
      log.error(e);
      return { kind: 'bad-data' };
    }
  }

  async getDistrictList(
    regencyId: number,
    keyword: string,
  ): Promise<GeneralApiResult> {
    if (regencyId < 0) return { kind: 'rejected' };
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(
        DISTRICT,
        { regencyId, keyword },
      );

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const generalResponse: GeneralDataResponse = response.data;

      if (response.ok) {
        try {
          return { kind: 'ok', data: generalResponse };
        } catch {
          return { kind: 'bad-data' };
        }
      }
    } catch (e) {
      log.error(e);
      return { kind: 'bad-data' };
    }
  }

  async getVillageList(
    districtId: number,
    keyword: string,
  ): Promise<GeneralApiResult> {
    if (districtId < 0) return { kind: 'rejected' };
    try {
      // make the api call
      const response: ApiResponse<any> = await this.api.apisauce.post(VILLAGE, {
        districtId,
        keyword,
      });

      // the typical ways to die when calling an api
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const generalResponse: GeneralDataResponse = response.data;

      if (response.ok) {
        try {
          return { kind: 'ok', data: generalResponse };
        } catch {
          return { kind: 'bad-data' };
        }
      }
    } catch (e) {
      log.error(e);
      return { kind: 'bad-data' };
    }
  }
}
