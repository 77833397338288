import {
  addHours,
  addMinutes,
  addSeconds,
  format,
  parseISO,
  setMinutes,
} from 'date-fns';
import { id } from 'date-fns/locale';
import { isEmptyString } from './account-helper';

const locale = id;

const MONTHS = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
];

const INVALID_DATE = '0001-01-01T00:00:00Z';

export function isDateValid(d: string) {
  if (!d || isEmptyString(d)) return false;
  return d !== INVALID_DATE;
}

export function checkDate(date: Date | string | undefined | null): Date {
  if (!date) {
    return new Date();
  }

  if (typeof date === 'string') {
    return parseISO(date as string);
  }

  return date as Date;
}

/**
 * Return Date ===> HH:mm
 */
export function formatHour(str) {
  return format(checkDate(str), 'HH:mm', { locale });
}

/**
 * Return Date ===> 2022
 */
export function formatYearString(str) {
  return format(checkDate(str), 'yyyy', { locale });
}

export function formatDate(str, dateFormat?: string) {
  return format(checkDate(str), dateFormat ?? 'dd MMMM yyyy', { locale });
}

export function formatFullDateWithHour(str) {
  return format(checkDate(str), 'dd MMMM yyyy HH:mm:ss', { locale });
}

/**
 * Return Date ===> 20-02-2022 12:52:02
 */
export function formatDateWithHour(str) {
  return format(checkDate(str), 'dd-MM-yyyy HH:mm:ss', { locale });
}

/**
 * Return Date ===> 20-02-2022 12:52:02
 */
export function formatDateWithHourWithoutSeconds(str) {
  return format(checkDate(str), 'dd-MM-yyyy HH:mm', { locale });
}

/**
 * Return Date ===> 20-02-2022
 */
export function formatDateForm(date: Date | string) {
  return format(checkDate(date), 'dd-MM-yyyy', { locale });
}

/**
 * Return Date ===> Rabu, 14 Februari 2024
 */
export function formatDateWithDay(date: Date | string) {
  return format(checkDate(date), 'dd-MM-yyyy', { locale });
}

/**
 * Return Date ===> 2022-02-20
 */
export function formatDateUCO(date: Date | string) {
  return format(checkDate(date), 'yyyy-MM-dd', { locale });
}

/**
 * Return Date ===> "YYYY-MM-DDTHH:mm:ssZ"
 */
export function formatPost(date: Date | string) {
  return (
    format(checkDate(date), 'yyyy-MM-dd', { locale }) +
    'T' +
    format(checkDate(date), 'HH:mm:ss', { locale }) +
    'Z'
  );
}

/**
 * Return Date ===> "YYYY-MM-DDTHH:mm:ss+07:00"
 */
export function formatPostUCO(date: Date | string | undefined) {
  return (
    format(checkDate(date), 'yyyy-MM-dd', { locale }) +
    'T' +
    format(checkDate(date), 'HH:mm:ss', { locale }) +
    '+07:00'
  );
}

export function convertTimestampToDate(a: any) {
  if (a?.seconds) return new Date(a.seconds * 1000);
  if (!a) return new Date();
  return checkDate(a);
}

export function checkDeletedAt(a: any) {
  if (!a) {
    return true;
  }
  return formatDateForm(new Date(a.seconds * 1000)) === '01-01-0001';
}

// Thu Nov 24 2022 00:00:00 GMT+0700 (Indochina Time)
export function convertStringDateToDate(s: string | undefined) {
  if (!s) return;
  if (s.includes('-')) return checkDate(s);
  if (s.includes(':') && !s.includes('GMT')) {
    const time = s.split(':');
    const baseDate = checkDate(new Date(1, 1, 1));
    return addHours(
      addMinutes(
        addSeconds(baseDate, Number(time[2] ?? 0)),
        Number(time[1] ?? 0),
      ),
      Number(time[0] ?? 0),
    );
  }
  const dateSplit = s.split(' ');
  if (dateSplit.length === 0) return;
  const day = dateSplit[2];
  const month = MONTHS.indexOf(dateSplit[1]?.toLowerCase());
  const year = dateSplit[3];

  const timeSplit = dateSplit[4]?.split(':');
  const date = checkDate(
    new Date(Number(year ?? 0), Number(month ?? 0), Number(day ?? 0)),
  );
  return setMinutes(
    addHours(date, Number(timeSplit[0] ?? 0)),
    Number(timeSplit[1] ?? 0),
  );
}

export const _formatDate = (date?: string, dateFormat?: string) => {
  if (!date) return '-';
  if (date === INVALID_DATE) return '-';
  const dateOptions = {
    locale,
  };
  return format(checkDate(date), dateFormat ?? 'MMM dd, yyyy', dateOptions);
};
