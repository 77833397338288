import imageCompression from 'browser-image-compression';
import {
  MAX_COMPRESSED_IMAGE_SIZE,
  MAX_COMPRESSED_IMAGE_WIDTH,
} from '../config/global.config';
export const compressImage = async (file: File) => {
  const options = {
    maxSizeMB: MAX_COMPRESSED_IMAGE_SIZE,
    maxWidthOrHeight: MAX_COMPRESSED_IMAGE_WIDTH,
    useWebWorker: true,
    alwaysKeepResolution: false,
  };
  return await imageCompression(file, options);
};
