import React from 'react';
import { ICheckboxProps } from './checkbox.props';
import { classNames } from 'utils';

export const Checkbox: React.FC<ICheckboxProps> = props => {
  return (
    <div className="flex flex-row mb-2">
      <input
        id={props.id}
        type="checkbox"
        className={classNames('form-checkbox h-5 w-5 rounded', props.className)}
        onChange={props.onChange}
        checked={props.checked}
        defaultChecked={props.defaultChecked}
        disabled={props.disabled}
      />
      <label
        htmlFor={props.id}
        className="ml-2 text-sm font-medium text-gray-700 "
      >
        {props.label}
      </label>
    </div>
  );
};
