/**
 *
 * Banner Form Edit
 *
 */
import React, { useEffect, useState } from 'react';
import { MainNavigator } from '../../../navigators/mainNavigator';
import { Link, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { logger } from '../../../utils/logger';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import { BannerFormDefault, useBannerSlice } from '../../modules/banner/slice';
import {
  selectBannerForm,
  selectBannerList,
  selectBannerLoading,
  selectBannerSubmitSuccess,
} from '../../modules/banner/slice/selectors';
import { BannerFormType, BannerType } from '../../../types/Banner';
import Body from 'react-data-table-component/dist/src/DataTable/TableBody';

/**
 * Logger
 */
const log = logger().child({ module: 'BannerForm' });

interface BannerCreateParams {
  id: string;
}

export const BannerForm = () => {
  const dispatch = useDispatch();
  const { actionsBanner } = useBannerSlice();
  const { id }: BannerCreateParams = useParams();
  const bannerList = useSelector(selectBannerList);

  const title = !id ? `Create Banner` : `Banner Edit`;
  const loading = useSelector(selectBannerLoading);
  const [data, setData] = useState<BannerFormType>(BannerFormDefault);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [displayImage, setDisplayImage] = useState<any>();
  const [updateImage, setUpdateImage] = useState(false);

  useEffect(() => {
    if (id) {
      const banners = bannerList.filter(b => b.id === Number(id));
      if (banners.length > 0) {
        const sb: BannerType = banners[0];
        setData({
          name: sb.name,
          action: sb.action,
          fileImage: sb.imageUrl,
          id: sb.id,
          sequence: sb.sequence,
        });
        setDisplayImage(sb.imageUrl);
      } else {
        window.location.assign('/banner-list');
      }
    }
  }, [id]);

  const Content = () => {
    const isSubmitSuccess = useSelector(selectBannerSubmitSuccess);

    const onSelectFile = e => {
      if (!e.target.files || e.target.files.length === 0) {
        setSelectedFile('');
        return;
      }
      setSelectedFile(e.target.files[0]);
      setDisplayImage(URL.createObjectURL(e.target.files[0]));
      setUpdateImage(true);
    };

    const onSubmit = async (data: BannerFormType) => {
      const body: BannerFormType = data;
      if (selectedFile && updateImage) {
        body.fileImage = selectedFile;
      } else {
        body.fileImage = undefined;
      }
      await dispatch(actionsBanner.setBannerForm(body));
      if (id) {
        await dispatch(actionsBanner.submitUpdate());
      } else {
        await dispatch(actionsBanner.submitBanner());
      }
    };

    useEffect(() => {
      if (isSubmitSuccess) {
        dispatch(actionsBanner.submitReset);
        setTimeout(() => {
          window.location.assign('/banner-list');
          setUpdateImage(false);
        }, 1000);
      }
    }, [isSubmitSuccess]);

    return (
      <div className={'pb-8'}>
        {/*<AlertSection />*/}
        <Formik initialValues={data} onSubmit={onSubmit}>
          {({
            values,
            setFieldValue,
            /* and other goodies */
          }) => (
            <Form>
              <div className="space-y-6 divide-y divide-gray-200 px-6">
                <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                  <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                      <label
                        htmlFor="cover-photo"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Photo Banner
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                          {displayImage ? (
                            <div className="row">
                              <div>
                                <img
                                  className="relative"
                                  src={displayImage}
                                  style={{ maxHeight: 300, maxWidth: 350 }}
                                  alt=""
                                />
                              </div>

                              <div className="flex text-sm text-gray-600 mt-4">
                                <label
                                  htmlFor="file-upload"
                                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                >
                                  <span>Edit Image</span>
                                  <input
                                    id="file-upload"
                                    name="file-upload"
                                    type="file"
                                    className="sr-only"
                                    onChange={onSelectFile}
                                  />
                                </label>
                              </div>
                            </div>
                          ) : (
                            <div className="space-y-1 text-center">
                              <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48"
                                aria-hidden="true"
                              >
                                <path
                                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <div className="flex text-sm text-gray-600">
                                <label
                                  htmlFor="file-upload"
                                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                >
                                  <span>Upload a file</span>
                                  <input
                                    id="file-upload"
                                    name="file-upload"
                                    type="file"
                                    className="sr-only"
                                    onChange={onSelectFile}
                                  />
                                </label>
                                <p className="pl-1">or drag and drop</p>
                              </div>
                              <p className="text-xs text-gray-500">
                                PNG, JPG, GIF up to 10MB
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Nama Banner
                      </label>
                      <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          autoComplete="name"
                          onChange={e => setFieldValue('name', e.target.value)}
                          value={values.name}
                          placeholder="Name"
                          className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                      <label
                        htmlFor="operational-time"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Urutan
                      </label>
                      <div
                        id="operational-time"
                        className="max-w-lg mt-1 sm:mt-0 sm:col-span-2"
                      >
                        <input
                          type="number"
                          name="sequence"
                          id="sequence"
                          autoComplete="sequence"
                          min={0}
                          value={values.sequence}
                          onChange={e =>
                            setFieldValue('sequence', e.target.value)
                          }
                          placeholder="1"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
                      <label
                        htmlFor="operational-day"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Action
                      </label>
                      <div
                        id="operational-day"
                        className="max-w-lg mt-1 sm:mt-0 sm:col-span-2"
                      >
                        <input
                          type="text"
                          name="action"
                          id="action"
                          autoComplete="action"
                          value={values.action}
                          onChange={e =>
                            setFieldValue('action', e.target.value)
                          }
                          placeholder="link or screen"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <input
                      type="text"
                      name="id"
                      id="id"
                      value={id ?? ''}
                      style={{ display: 'none' }}
                      readOnly
                    />
                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-between">
                    <Link to={'/banner-list'}>
                      <button
                        type="button"
                        className="bg-white py-2 px-5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Back
                      </button>
                    </Link>
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <LoadingOverlay isActive={loading} />
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return <MainNavigator content={<Content />} title={title} />;
};
