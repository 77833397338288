/**
 *
 * Rebox Detail
 *
 */
import React, { memo, useEffect, useState } from 'react';
import { MainNavigator } from '../../../navigators/mainNavigator';
import { Link, useParams } from 'react-router-dom';
import { logger } from '../../../utils/logger';
import { PencilIcon, XIcon } from '@heroicons/react/solid';

import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '../../components/Button';
import { ModalConfirm } from '../../components/ModalConfirm';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useDispatch, useSelector } from 'react-redux';
import { useBannerSlice } from '../../modules/banner/slice';
import {
  selectBannerList,
  selectBannerLoading,
} from '../../modules/banner/slice/selectors';
import { BannerType } from '../../../types/Banner';
import { LoadingOverlay } from '../../components/LoadingOverlay';

/**
 * Logger
 */
const log = logger().child({ module: 'DropStationDetail' });

interface Props {
  title?: string;
}

interface BannerDetailParams {
  id: string;
}

export const BannerDetail = memo((props: Props) => {
  let { id }: BannerDetailParams = useParams();
  const { actionsBanner } = useBannerSlice();
  const bannerList = useSelector(selectBannerList);
  const loading = useSelector(selectBannerLoading);
  const dispatch = useDispatch();

  const [data, setData] = useState<BannerType | undefined>(undefined);
  const [showModal, setModal] = useState<boolean>(false);

  const deleteBanner = async () => {
    dispatch(actionsBanner.setLoading(true));
    setModal(false);
    if (!id) return null;
    dispatch(actionsBanner.setSelectedBannerID(Number(id)));
    dispatch(actionsBanner.loadDeleteBanner());
    setTimeout(() => {
      window.location.assign('/banner-list');
      dispatch(actionsBanner.setLoading(false));
    }, 1000);
  };

  const getDetail = async () => {
    const banners = bannerList.filter(b => b.id === Number(id));
    if (banners.length > 0) {
      setData(banners[0]);
    }
  };

  useEffect(() => {
    if (id) {
      getDetail();
    }
  }, [id]);

  useEffect(() => {
    if (bannerList.length === 0) {
      window.location.assign('/banner-list');
    }
  }, [bannerList]);

  const Content = () => {
    return (
      <div className="p-6 mb-3">
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Nama
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      disabled
                      value={data?.name}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="region"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Urutan
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      disabled
                      value={data?.sequence}
                      type="text"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="postal-code"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Action
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      value={data?.action}
                      disabled
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="cover-photo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Gambar Banner
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <div className="space-y-1 text-center">
                      <img
                        className="object-cover shadow-lg rounded-lg"
                        style={{ maxHeight: 300, maxWidth: 400 }}
                        src={data?.imageUrl}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5"></div>
        </div>

        <ModalConfirm
          show={showModal}
          title="Delete Banner"
          isPositiveCase={false}
          body="Are you sure you want to delete this banner?"
          positiveText="Delete"
          onNegativeClick={() => setModal(false)}
          onPositiveClick={deleteBanner}
          icon={
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
              <XIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
            </div>
          }
        />
      </div>
    );
  };

  return (
    <MainNavigator
      content={<Content />}
      title={props.title}
      topButton={
        <div>
          <Button
            onClick={() => setModal(true)}
            type={'danger'}
            className=""
            icon={<XIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />}
          >
            Delete Banner
          </Button>
          <span className="mx-2 md:mt-8" />
          <Link to={'/banner/' + id + '/edit'}>
            <Button
              type={'primary'}
              icon={
                <PencilIcon
                  className="ml-2 -mr-0.5 h-4 w-4"
                  aria-hidden="true"
                />
              }
            >
              Edit Banner
            </Button>
          </Link>
          <LoadingOverlay isActive={loading} />
        </div>
      }
    />
  );
});
