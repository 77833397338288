export function currencyFormatter(
  amount: number | null | undefined,
  prefix = 'Rp.',
) {
  if (!amount) return '';
  const formatAmount = Number(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    .split('.');

  return `${prefix} ${formatAmount[0]?.replaceAll(',', '.')}`;
}
