export const downloadBase64 = (
  base64: string,
  fileName: string,
  type: string,
) => {
  if (base64 && fileName) {
    // Convert base64 to binary
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create Blob
    const blob = new Blob([byteArray], {
      type: type,
    });

    // Create URL
    const url = window.URL.createObjectURL(blob);

    // Create temporary link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}`);

    // Append link to body
    document.body.appendChild(link);

    // Programmatically click the link
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }
};
