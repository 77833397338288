export function removeDuplicates(originalArray, prop) {
  let newArray: any[] = [];
  let lookupObject: any = {};

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
}

export function removeDuplicatesById(array: any[]) {
  if (array.length === 0) return [];
  return array.filter((v, i, a) => a.findIndex(c => c.id === v.id) === i);
}
