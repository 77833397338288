import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './index';

const selectSlice = (state: RootState) => state.banner || initialState;

export const selectBannerPaginationParams = createSelector(
  [selectSlice],
  state => state.paginationParams,
);

export const selectBannerPagination = createSelector(
  [selectSlice],
  state => state.pagination,
);

export const selectBannerList = createSelector(
  [selectSlice],
  state => state.list,
);

export const selectedBannerId = createSelector(
  [selectSlice],
  state => state.selectedBannerId,
);

export const selectBannerLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

export const selectErrorBanner = createSelector(
  [selectSlice],
  state => state.error,
);

export const selectDeleteBannerSuccess = createSelector(
    [selectSlice],
    state => state.deleteSuccess,
);

export const selectBannerSubmitSuccess = createSelector(
  [selectSlice],
  state => state.submitSuccess,
);

export const selectBannerForm = createSelector(
    [selectSlice],
    state => state.bannerForm,
);
