/**
 *
 * Asynchronously loads the component for Banner
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Reboxes = lazyLoad(
  () => import('./index'),
  module => module.Reboxes,
);

export const ReboxForm = lazyLoad(
    () => import('./form'),
    module => module.ReboxForm,
);

export const ReboxDetail = lazyLoad(
    () => import('./details'),
    module => module.ReboxDetail,
);

