/**
 *
 * VStack
 *
 */
import * as React from 'react';
import { classNames } from 'utils';
import { IButtonProps } from './button.props';

export const Button: React.FC<IButtonProps> = props => {
  if (props.type === 'primary') {
    return (
      <button
        onClick={props.onClick}
        type="button"
        className={classNames(
          'inline-flex items-center px-4 py-2 border border-beauBlue-300 text-sm font-medium rounded-full shadow-sm text-white bg-raisinBlack-900 hover:bg-beauBlue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-beauBlue-50 disabled:text-beauBlue-300',
          props.className,
        )}
        disabled={props.disabled}
        style={props.style}
      >
        {props.leftIcon}
        {props.children}
        {props.icon}
      </button>
    );
  }

  if (props.type === 'secondary') {
    return (
      <button
        onClick={props.onClick}
        type="button"
        className={classNames(
          'inline-flex items-center px-4 py-2 border border-beauBlue-300 text-sm font-medium rounded-full shadow-sm text-raisinBlack-800 bg-white hover:bg-beauBlue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
          props.className,
        )}
        disabled={props.disabled}
        style={props.style}
      >
        {props.leftIcon}
        {props.children}
        {props.icon}
      </button>
    );
  }

  if (props.type === 'danger') {
    return (
      <button
        onClick={props.onClick}
        type="button"
        className={classNames(
          'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
          props.className,
        )}
        disabled={props.disabled}
        style={props.style}
      >
        {props.children}
        {props.icon}
      </button>
    );
  }

  if (props.type === 'transparent') {
    return (
      <button
        onClick={props.onClick}
        type="button"
        className={classNames(props.className)}
        disabled={props.disabled}
        style={props.style}
      >
        {props.children}
        {props.icon}
      </button>
    );
  }

  return (
    <div
      className={classNames('flex flex-row', props.className)}
      style={props.style}
    >
      {props.children}
    </div>
  );
};
