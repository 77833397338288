/**
 *
 * Asynchronously loads the component for Banner
 *
 */

import { lazyLoad } from 'utils/loadable';

export const DropIn = lazyLoad(
  () => import('./index'),
  module => module.DropIn,
);

export const DropInDetail = lazyLoad(
  () => import('./details'),
  module => module.DropInDetail,
);

export const DropInFormSort = lazyLoad(
    () => import('./form-sort'),
    module => module.DropInFormSort,
);

export const DropInFormReceive = lazyLoad(
    () => import('./form-receiving'),
    module => module.DropInFormReceive,
);
