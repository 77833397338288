import { call, put, select, takeLatest } from 'redux-saga/effects';
import { DropInActions as actions } from '.';
import {
  selectDropCode,
  selectDropIn,
  selectDropInPaginationParams,
  selectDropInReceiveForm,
  selectDropInSortForm,
  selectDropInWastetForm,
} from './selectors';
import {
  PaginationParams,
  DropInReceiveParamType,
  DropInSortParamType,
  DropWasteParamType,
  EprPartnerSortFormItemType,
  EprPartnerReceiveFormItemType,
} from 'types';
import { selectApi } from '../../../../api/slice/selectors';
import { formatPostUCO } from 'utils';
import {
  DROP_CODE,
  DROP_IN,
  DROP_IN_DETAIL,
  DROP_IN_RECEIVE,
  DROP_IN_RECEIVE_FORM,
  DROP_IN_SORT,
  DROP_IN_SORT_FORM,
  DROP_IN_SORTER,
  DROP_IN_SUBMIT_EPR,
  DROP_IN_WITHOUT_CODE,
  GeneralSubmitResult,
  GenericApiResult,
} from '../../../../api/api.types';
import {
  ApiKind,
  ApiMethod,
  isMultipart,
  isUco,
} from '../../../../config/global.config';
import { DropInState } from './types';
import { isEmpty } from 'lodash';

function* getDropInList() {
  const { page, limit, keyword }: PaginationParams = yield select(
    selectDropInPaginationParams,
  );
  const api = yield select(selectApi);

  try {
    const params = {
      page,
      limit,
      keyword,
    };
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.GET,
      !isMultipart,
      DROP_IN,
      !isUco,
      params,
    );
    if (response.kind === ApiKind.OK)
      yield put(actions.listLoaded(response.data.data));
  } catch (err: any) {
    yield put(actions.listError(err));
  }
}

function* getDropInDetail() {
  const dropCode = yield select(selectDropCode);
  const api = yield select(selectApi);

  try {
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.GET,
      !isMultipart,
      DROP_IN_DETAIL,
      !isUco,
      { dropCode },
    );
    if (response.kind === ApiKind.OK)
      yield put(actions.dropInDetailLoaded(response.data.data));
  } catch (err: any) {
    yield put(actions.dropInDetailError(err));
  }
}

function* deleteDropIn() {
  const dropCode = yield select(selectDropCode);
  const api = yield select(selectApi);

  try {
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.DELETE,
      !isMultipart,
      DROP_IN + '?dropCode=' + dropCode,
      !isUco,
    );
    if (response.kind === ApiKind.OK)
      yield put(actions.deleteDropInSuccess(response.data.data));
  } catch (err: any) {
    yield put(actions.dropInDetailError(err));
  }
}

function* getDropInSorter() {
  const api = yield select(selectApi);
  try {
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.GET,
      !isMultipart,
      DROP_IN_SORTER,
      !isUco,
    );
    if (response.kind === ApiKind.OK)
      yield put(actions.loadedSorter(response.data.data));
  } catch (err: any) {
    yield put(actions.errorLoadSorter(err));
  }
}

function* getDropInGeneratedCode() {
  const api = yield select(selectApi);

  try {
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.POST,
      !isMultipart,
      DROP_IN_WITHOUT_CODE,
      !isUco,
    );
    if (response.kind === ApiKind.OK)
      yield put(actions.loadedGeneratedDropCode(response.data.data.dropCode));
  } catch (err: any) {
    yield put(actions.errorGeneratedDropCode(err));
  }
}

function* submitDropInSort() {
  const formWaste: DropWasteParamType = yield select(selectDropInWastetForm);
  const formData: DropInSortParamType = yield select(selectDropInSortForm);
  const { eprSortingForm }: DropInState = yield select(selectDropIn);

  const api = yield select(selectApi);

  try {
    const formDataUpload = new FormData();
    formDataUpload.append('voucher', formData.voucher);
    formDataUpload.append(
      'customVoucherReward',
      formData.customVoucherReward ?? '',
    );
    formDataUpload.append('dropCode', formData.dropCode ?? '');
    formDataUpload.append('dropStationId', formData.dropStationId ?? '');
    formDataUpload.append(
      'inorganicPackage',
      String(formData.inorganicPackage ?? '1'),
    );
    formDataUpload.append('ucoContainer', String(formData.ucoContainer) ?? '');
    formDataUpload.append(
      'nestlePackage',
      String(formData.nestlePackage) ?? '',
    );
    formDataUpload.append(
      'sortDate',
      formData.sortDate ? String(formData.sortDate) : '',
    );
    formDataUpload.append(
      'sortStartTime',
      formData.sortStartTime ? String(formData.sortStartTime) : '',
    );
    formDataUpload.append(
      'sortEndTime',
      formData.sortEndTime ? String(formData.sortEndTime) : '',
    );
    formDataUpload.append('sorterName', formData.sorterName ?? '');
    formDataUpload.append('dropWaste', JSON.stringify(formWaste) ?? '');

    // Append EPR waste container data
    if (formData.eprWasteContainer.length > 0) {
      formData.eprWasteContainer.map(eprWaste => {
        formDataUpload.append('eprPartnerId[]', eprWaste.eprPartnerId);
        formDataUpload.append(
          'eprPartnerNamePKG[]',
          eprWaste.eprPartnerNamePKG,
        );
        formDataUpload.append(
          'eprPartnerTotalPKG[]',
          String(eprWaste.eprPartnerTotalPKG),
        );
      });
    }

    // Append EPR waste sorting data

    // eprWasteItem

    const sortForm = formWaste.eprSortForm.filter(
      epr => epr.eprWasteItem.length > 0,
    );

    if (sortForm) {
      formDataUpload.append('eprWasteItem', JSON.stringify(sortForm));
    }

    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      formData.isEditData ? ApiMethod.PUT : ApiMethod.POST,
      true,
      DROP_IN_SORT,
      !isUco,
      formDataUpload,
    );

    if (response.kind === ApiKind.OK)
      yield put(actions.submitSortSuccess(response.data));
    else {
      yield put(
        actions.submitSortError({
          errors: true,
          message: `${response.kind.toUpperCase()} ${
            response.response?.message ?? ''
          }`,
        }),
      );
    }
  } catch (err: any) {
    yield put(actions.submitSortError(err));
  }
}

function* submitDropInReceive() {
  const formData: DropInReceiveParamType = yield select(
    selectDropInReceiveForm,
  );
  const api = yield select(selectApi);

  try {
    const formDataUpload = new FormData();
    formDataUpload.append('dropCode', formData.dropCode ?? '');
    formDataUpload.append('dropStationId', formData.dropStationId ?? '');
    formDataUpload.append(
      'receivedAt',
      formatPostUCO(formData.receivedAt) ?? '',
    );
    formDataUpload.append('receiverName', formData.receiverName ?? '');
    formDataUpload.append(
      'inorganicPackage',
      String(formData.inorganicPackage ?? 0),
    );
    formDataUpload.append(
      'inorganicWeight',
      String(formData.inorganicWeight ?? 0),
    );
    formDataUpload.append('residueWeight', String(formData.residueWeight ?? 0));
    formDataUpload.append('ucoContainer', String(formData.ucoContainer ?? 0));
    formDataUpload.append('ucoWeight', String(formData.ucoWeight ?? 0));
    formDataUpload.append('nestlePackage', String(formData.nestlePackage ?? 0));
    formDataUpload.append(
      'nestleWasteWeight',
      String(formData.nestleWasteWeight ?? 0),
    );
    if (formData.fileImage)
      formDataUpload.append('fileImage', formData.fileImage);
    if (formData.deleteImage)
      formDataUpload.append('deleteImage', `${formData.deleteImage}`);

    // Append EPR waste container data
    if (!isEmpty(formData.eprWasteWeight)) {
      formData.eprWasteWeight?.forEach(eprWaste => {
        formDataUpload.append('eprPartnerId[]', eprWaste.eprPartnerId);
        formDataUpload.append(
          'eprPartnerNamePKG[]',
          eprWaste.eprPartnerNamePKG,
        );
        formDataUpload.append(
          'eprPartnerTotalPKG[]',
          String(eprWaste.eprPartnerTotalPKG),
        );
        formDataUpload.append(
          'eprPartnerTotalWeight[]',
          String(eprWaste.eprPartnerTotalWeight),
        );
      });
    }

    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      formData.isEditData ? ApiMethod.PUT : ApiMethod.POST,
      true,
      DROP_IN_RECEIVE,
      !isUco,
      formDataUpload,
    );

    if (response.kind === ApiKind.OK)
      yield put(actions.submitReceiveSuccess(response.data));
    else {
      yield put(
        actions.submitReceiveError({
          errors: true,
          message: `${response.kind.toUpperCase()} ${
            response.response?.message ?? ''
          }`,
        }),
      );
    }
  } catch (err: any) {
    yield put(actions.submitReceiveError(err));
  }
}

function* getDropInOverview() {
  const { page, limit, keyword, status, dropStationId }: PaginationParams =
    yield select(selectDropInPaginationParams);
  const api = yield select(selectApi);

  try {
    const params = {
      page,
      limit,
      keyword,
      dropStationId,
      status,
    };
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.GET,
      !isMultipart,
      DROP_IN,
      !isUco,
      params,
    );
    if (response.kind === ApiKind.OK)
      yield put(actions.listOverviewLoaded(response.data.data));
  } catch (err: any) {
    yield put(actions.listOverviewError(err));
  }
}

function* getDropCodeSaga() {
  const { keyword, status }: PaginationParams = yield select(
    selectDropInPaginationParams,
  );
  const api = yield select(selectApi);

  try {
    const params = {
      keyword,
      status,
    };
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.GET,
      !isMultipart,
      DROP_CODE,
      !isUco,
      params,
    );
    if (response.kind === ApiKind.OK)
      yield put(actions.listDropCodeLoaded(response.data.data));
  } catch (err: any) {
    yield put(actions.listDropCodeError(err));
  }
}

function* getDropStationEPRSortForm() {
  const api = yield select(selectApi);

  try {
    const response: GenericApiResult<EprPartnerSortFormItemType[]> = yield call(
      [api, 'generalApi'],
      ApiMethod.GET,
      !isMultipart,
      DROP_IN_SORT_FORM,
      !isUco,
    );

    if (response.kind === ApiKind.OK)
      yield put(actions.eprSortFormLoaded(response.data.data));
  } catch (err: any) {
    yield put(actions.eprSortFormError(err));
  }
}

function* submitEPRSortForm() {
  const { eprSortingForm }: DropInState = yield select(selectDropIn);
  const api = yield select(selectApi);

  const sortForm = eprSortingForm?.eprWasteList.filter(
    epr => epr.eprWasteItem.length > 0,
  );

  try {
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.POST,
      !isMultipart,
      DROP_IN_SUBMIT_EPR + `/${eprSortingForm?.wasteCode}`,
      !isUco,
      {
        sortForm,
      },
    );

    if (response.kind === ApiKind.OK)
      yield put(actions.submitEprSortFormSuccess(response.data.data));
  } catch (err: any) {
    yield put(actions.submitEprSortFormError(err));
  }
}

function* getDropStationReceiveForm() {
  const api = yield select(selectApi);

  try {
    const response: GenericApiResult<EprPartnerReceiveFormItemType[]> =
      yield call(
        [api, 'generalApi'],
        ApiMethod.GET,
        !isMultipart,
        DROP_IN_RECEIVE_FORM,
        !isUco,
      );

    if (response.kind === ApiKind.OK)
      yield put(actions.eprReceiveFormLoaded(response.data.data));
  } catch (err: any) {
    yield put(actions.eprReceiveFormError(err));
  }
}

export function* DropInSaga() {
  yield takeLatest(actions.loadDropInList.type, getDropInList);
  yield takeLatest(actions.loadDropInDetail.type, getDropInDetail);
  yield takeLatest(actions.deleteDropIn.type, deleteDropIn);
  yield takeLatest(actions.submitSort.type, submitDropInSort);
  yield takeLatest(actions.submitReceive.type, submitDropInReceive);
  yield takeLatest(actions.loadGeneratedDropCode.type, getDropInGeneratedCode);
  yield takeLatest(actions.loadSorter.type, getDropInSorter);
  yield takeLatest(actions.loadDropInOverview.type, getDropInOverview);
  yield takeLatest(actions.loadDropCode.type, getDropCodeSaga);
  yield takeLatest(actions.loadEPRSortForm.type, getDropStationEPRSortForm);
  yield takeLatest(actions.submitEprSortForm.type, submitEPRSortForm);
  yield takeLatest(actions.loadEprReceiveForm.type, getDropStationReceiveForm);
}
