import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './index';

const selectSlice = (state: RootState) => state.auth || initialState;

export const selectLoginData = createSelector(
  [selectSlice],
  state => state.loginData,
);
export const selectSelectedUserId = createSelector(
  [selectSlice],
  state => state.selectedUserId,
);
export const selectUserData = createSelector(
  [selectSlice],
  state => state.userData,
);
export const selectAccessToken = createSelector(
  [selectSlice],
  state => state.userData.accessToken,
);
export const selectRefreshToken = createSelector(
  [selectSlice],
  state => state.refreshToken,
);

export const selectErrors = createSelector(
  [selectSlice],
  state => state.errors,
);

export const selectMessage = createSelector(
  [selectSlice],
  state => state.message,
);

export const selectProfileLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

// Account manager
export const selectAccountListPaginationParams = createSelector(
  [selectSlice],
  state => state.paginationParams,
);

export const selectAccountListPagination = createSelector(
  [selectSlice],
  state => state.pagination,
);

export const selectAccountList = createSelector(
  [selectSlice],
  state => state.accountList,
);

export const selectAccountListByStatus = createSelector(
  [selectSlice],
  state => state.accountListByStatus,
);

export const selectAccountId = createSelector(
  [selectSlice],
  state => state.selectedAccountId,
);

export const selectAccountDetail = createSelector(
  [selectSlice],
  state => state.accountDetail,
);

export const selectFormEditAccount = createSelector(
  [selectSlice],
  state => state.formEditAccount,
);

export const selectSubmitProfileSuccess = createSelector(
  [selectSlice],
  state => state.submitSuccess,
);

export const selectAddAccount = createSelector(
  [selectSlice],
  state => state.addAccount,
);

export const selectRole = createSelector([selectSlice], state => state.role);

export const selectDeleteAccountId = createSelector(
  [selectSlice],
  state => state.selectedDeleteAccountId,
);

export const selectProfileId = createSelector(
  [selectSlice],
  state => state.profileId,
);

export const selectProfileDetail = createSelector(
  [selectSlice],
  state => state.profileDetail,
);
