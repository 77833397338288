import React from 'react';
import Select, { components } from 'react-select';
import { ICustomSelectProps } from './custom-select.props';

export const CustomSelect: React.FC<ICustomSelectProps> = props => {
  const { options, onDelete, onChange, disabled } = props;

  const Option = props => {
    return (
      <>
        <components.Option {...props}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              position: 'relative',
              zIndex: 9999,
            }}
          >
            <div>{props.children}</div>
            <div>
              <button
                onClick={e => {
                  e.stopPropagation();
                  if (onDelete) {
                    onDelete(props.data);
                  }
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </components.Option>
      </>
    );
  };

  return (
    <div>
      <Select
        options={options}
        onChange={e => {
          if (onChange) {
            onChange(e);
          }
        }}
        components={{ Option }}
        styles={{
          option: base => ({
            ...base,
            position: 'relative',
            zIndex: 9999,
          }),
        }}
        isDisabled={disabled}
      />
    </div>
  );
};
