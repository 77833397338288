import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { IDropDownsProps } from './dropdowns.props';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const Dropdowns: React.FC<IDropDownsProps> = props => {
  const CLASS_SELECTED = 'bg-gray-100 text-gray-900';
  const CLASS_DEFAULT = 'text-gray-700';
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {props.selected ? props.selected.slice(0, 12) : props.defaultValue}
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1 max-h-80 overflow-y-auto">
            {props.options.map((o, i) => (
              <Menu.Item key={i}>
                <button
                  onClick={() => props.onSelected(o)}
                  className={classNames(
                    o === props.selected ? CLASS_SELECTED : CLASS_DEFAULT,
                    'block px-4 py-2 text-sm w-full',
                  )}
                >
                  <span className={'text-xs'}>{o}</span>
                </button>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
