import { ReactSelectOption } from '../app/components/ReactSelect';
import {
  DropCodeType,
  DropInType,
  DropStationKindType,
  DropStationType,
  OperationalDayType,
  OperationalPostType,
  StationType,
} from 'types';
import { SelectorOptionType } from 'types';
import { DAY_OPTION } from '../app/pages/DropStation/constants';

export const convertDropInTypeData = (data: DropInType[]) => {
  let newList: ReactSelectOption[] = [];
  data.forEach(d => {
    newList.push({
      label: d.dropCode,
      value: d.dropCode,
    });
  });
  return newList;
};

export const convertDataDropStation = (data: DropStationType[]) => {
  let newList: ReactSelectOption[] = [];
  data.forEach(d => {
    newList.push({
      label: d.name,
      value: d.name,
      id: d.id,
    });
  });
  return newList;
};

export const convertDropStation = (data: StationType[]) => {
  let newList: ReactSelectOption[] = [];
  data.forEach(d => {
    newList.push({
      label: d.name,
      value: d.name,
      id: d.id,
    });
  });
  return newList;
};

export const convertToOption = (d: DropCodeType[]) => {
  let dp: ReactSelectOption[] = [];
  d.forEach(c => {
    dp.push({
      id: c.id,
      value: c.name,
      label: c.name,
    });
  });
  return dp;
};

export const convertDropStationType = (data: DropStationKindType[]) => {
  const res: SelectorOptionType[] = [];
  if (data.length)
    data.forEach(item => {
      res.push({
        label: item.description,
        value: item.type,
      });
    });
  return res;
};

export const convertOperationalDay = (
  ops: OperationalDayType[] | undefined,
) => {
  const res: OperationalPostType[] = [];
  if (ops)
    ops.forEach(op => {
      res.push({
        day: op.value,
        isOpen: op.isOpen,
        operationalTime: op.operationalTime ?? '',
        breakTime: op.breakTime ?? '',
      });
    });
  return res;
};

export const convertResponseOperationalDay = (ops: OperationalPostType[]) => {
  const res: OperationalDayType[] = [];
  ops.forEach(op => {
    res.push({
      ...op,
      value: op.day,
      label: DAY_OPTION.filter(d => d.value === op.day)[0].label,
    });
  });
  return res;
};

export const isProgramMatch = (id: string | undefined, prefix: string) => {
  if (id) return id.includes(prefix);
  else return false;
};
