import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { BannerSaga } from './saga';
import { BannerState } from './types';
import { BannerFormType, BannerType } from '../../../../types/Banner';
import { PaginationParams } from '../../../../types/Pagination';
import { paginationDefault, paginationParamsDefault } from '../../pagination';
import { GeneralDataResponse } from '../../../../api/api.types';

export const BannerFormDefault: BannerFormType = {
  id: undefined,
  action: '',
  name: '',
  fileImage: '',
  sequence: 0,
};

export const initialState: BannerState = {
  list: [],
  selectedBannerId: undefined,
  bannerForm: undefined,
  loading: false,
  error: null,
  message: undefined,
  paginationParams: paginationParamsDefault,
  pagination: paginationDefault,
  deleteSuccess: false,
  submitSuccess: false,
  statusSuccess: false,
};

const slice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    setBannerList(state, action: PayloadAction<BannerType[]>) {
      state.list = action.payload;
    },
    setPaginationParams(state, action: PayloadAction<PaginationParams>) {
      state.paginationParams = action.payload;
    },
    setBannerForm(state, action: PayloadAction<BannerFormType>) {
      state.bannerForm = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setSelectedBannerID(state, action: PayloadAction<number>) {
      state.selectedBannerId = action.payload;
    },

    loadBannerList(state) {
      state.loading = true;
      state.error = null;
      state.list = [];
    },
    listLoaded(
      state,
      action: PayloadAction<{
        // pagination: Pagination;
        data: BannerType[];
      }>,
    ) {
      state.loading = false;
      state.error = null;
      // state.pagination = action.payload.pagination;
      state.list = action.payload.data;
    },
    listError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
      state.list = [];
    },

    loadDeleteBanner(state) {
      state.loading = true;
      state.error = null;
      state.message = '';
      state.deleteSuccess = false;
    },
    deleteSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.selectedBannerId = undefined;
      state.deleteSuccess = true;
    },
    deleteError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.deleteSuccess = false;
    },

    submitBanner(state) {
      state.loading = true;
      state.error = null;
      state.message = '';
      state.submitSuccess = false;
    },
    submitSuccess(state, action: PayloadAction<GeneralDataResponse>) {
      state.loading = false;
      state.submitSuccess = true;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.bannerForm = undefined;
    },
    submitError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.bannerForm = undefined;
      state.submitSuccess = false;
    },

    submitUpdate(state) {
      state.loading = true;
      state.error = null;
      state.submitSuccess = false;
    },
    updateSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.submitSuccess = action.payload.data;
    },
    updateError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
      state.submitSuccess = false;
    },

    submitReset(state) {
      state.loading = true;
      state.error = null;
      state.message = '';
      state.selectedBannerId = undefined;
      state.deleteSuccess = false;
      state.submitSuccess = false;
      state.bannerForm = undefined;
    },
  },
});

export const { actions: BannerActions } = slice;

export const useBannerSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: BannerSaga });
  return { actionsBanner: slice.actions };
};
