/**
 *
 * Asynchronously loads the component for Banner
 *
 */

import { lazyLoad } from 'utils/loadable';

export const UcoRoutinePickup = lazyLoad(
    () => import('./index'),
    module => module.UcoRoutinePickup,
);

export const UcoRoutinePickupEditData = lazyLoad(
    () => import('./edit-data'),
    module => module.UcoRoutinePickupEditData,
);

export const UcoRoutinePickupEditTransfer = lazyLoad(
    () => import('./edit-transfer'),
    module => module.UcoRoutinePickupEditTransfer,
);
