import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { IDropDownProps } from './dropdown.props';

export const Dropdown: React.FC<IDropDownProps> = props => {
  const { header, subHeader, children } = props;
  const [content, setContent] = useState<boolean>(false);

  return (
    <>
      {/*Data*/}
      <div className="mb-3" onClick={() => setContent(!content)}>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <div style={{ display: 'flex' }}>
              <h3
                className="text-lg leading-6 font-medium text-gray-900 mb-1"
                style={{ width: '100%' }}
              >
                {header}
              </h3>
              {content ? (
                <ChevronUpIcon
                  className="ml-2 -mr-0.5 h-6 w-6"
                  aria-hidden="true"
                />
              ) : (
                <ChevronDownIcon
                  className="ml-2 -mr-0.5 h-6 w-6"
                  aria-hidden="true"
                />
              )}
            </div>
            <h3 className="text-sm leading-3 font-small text-gray-900">
              {subHeader}
            </h3>
          </div>
        </div>
      </div>

      {content && <>{children}</>}
    </>
  );
};
