import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { DropStationSaga } from './saga';
import { DropStationState } from './types';
import {
  DropStationDetailType,
  DropStationType,
  EprPartnerItemType,
  OperationalDayType,
  SelectorOptionType,
  StationType,
} from 'types';
import { Pagination, PaginationParams } from 'types';
import { paginationDefault, paginationParamsDefault } from '../../pagination';
import { GeneralDataResponse } from '../../../../api/api.types';
import { DAY_OPTION } from '../../../pages/DropStation/constants';

const operationalDayDefault = () => {
  const res: OperationalDayType[] = [];
  DAY_OPTION.forEach(day =>
    res.push({
      ...day,
      isOpen: false,
      operationalTime: '',
      breakTime: '',
    }),
  );
  return res;
};

export const DropStationDetailDefault: DropStationType = {
  id: '1',
  createdAt: new Date(),
  updatedAt: new Date(),
  installedAt: new Date(),
  dropStationImg: 0,
  uuid: '',
  name: '',
  type: 1,
  typeName: '',
  longitude: 0,
  latitude: 0,
  address: '',
  rt: '',
  rw: '',
  postalCode: '',
  village: '',
  district: '',
  regency: '',
  province: '',
  status: false,
  disabled: 0,
  operationalTime: '',
  operationalDay: '',
  provinceId: undefined,
  regencyId: undefined,
  districtId: undefined,
  villageId: undefined,
  operational: operationalDayDefault(),
  keyAccess: '',
  confirmKeyAccess: '',
  description: '',
  collaborationProgramName: '',
  collaborationWebViewLink: '',
  eprPartners: [],
  isEditPassword: false,
  hasPassword: false,
};

export const DropStationDetailResponseDefault: DropStationDetailType = {
  id: 0,
  uuid: '',
  name: '',
  type: 1,
  typeName: '',
  status: false,
  address: '',
  rt: '',
  rw: '',
  latitude: 0,
  longitude: 0,
  operationalTime: '',
  operationalDay: [],
  dropStationImg: 0,
  dropStationImgUrl: '',
  banner: 0,
  bannerUrl: '',
  logo: 0,
  logoUrl: '',
  province: '',
  provinceId: 0,
  regency: '',
  regencyId: 0,
  district: '',
  districtId: 0,
  village: '',
  villageId: 0,
  disabled: false,
  description: '',
  program: '',
  programPrompt: [],
  url: '',
  postalCode: '',
  createdAt: '',
  updatedAt: '',
  installedAt: '',
  eprPartners: [],
  hasPassword: false,
};

export const initialState: DropStationState = {
  list: [],
  stations: [],
  vouchers: [],
  eprProgramType: [],
  dropStationType: [],
  data: DropStationDetailResponseDefault,
  dropStationForm: undefined,
  loading: false,
  error: null,
  paginationParams: paginationParamsDefault,
  pagination: paginationDefault,
  message: '',
  statusSuccess: false,
  submitSuccess: false,
  deleteSuccess: false,
  dropStationId: '0',
};

const slice = createSlice({
  name: 'dropStation',
  initialState,
  reducers: {
    setPaginationParams(state, action: PayloadAction<PaginationParams>) {
      state.paginationParams = action.payload;
    },
    setDropStationSelectedId(state, action: PayloadAction<string>) {
      state.dropStationId = action.payload;
    },
    setDropStationForm(state, action: PayloadAction<DropStationType>) {
      state.dropStationForm = action.payload;
    },
    setDropStationList(state, action: PayloadAction<DropStationType[]>) {
      state.list = action.payload;
    },
    loadDropStationList(state) {
      state.loading = true;
      state.error = null;
      state.list = [];
    },
    listLoaded(
      state,
      action: PayloadAction<{
        pagination: Pagination;
        data: DropStationType[];
      }>,
    ) {
      state.loading = false;
      state.error = null;
      state.pagination = action.payload.pagination;
      state.list = action.payload.data;
    },
    listError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
      state.list = [];
    },

    loadEPRProgramType(state) {
      state.loading = true;
      state.error = null;
      state.eprProgramType = [];
    },
    eprProgramTypeLoaded(state, action: PayloadAction<EprPartnerItemType[]>) {
      state.loading = false;
      state.error = null;
      state.eprProgramType = action.payload;
    },
    eprProgramTypeError(state, action: PayloadAction<GeneralDataResponse>) {
      state.loading = false;
      state.error = action.payload.errors;
      state.eprProgramType = [];
    },

    loadDropStationType(state) {
      state.loading = true;
      state.error = null;
      state.dropStationType = [];
    },
    dropStationTypeLoaded(state, action: PayloadAction<SelectorOptionType[]>) {
      state.loading = false;
      state.error = null;
      state.dropStationType = action.payload;
    },
    dropStationTypeError(state, action: PayloadAction<GeneralDataResponse>) {
      state.loading = false;
      state.error = action.payload.errors;
      state.dropStationType = [];
    },

    loadVouchers(state) {
      state.loading = true;
      state.error = null;
      state.vouchers = [];
    },
    voucherLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.vouchers = action.payload.data;
    },
    voucherError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
      state.vouchers = [];
    },

    loadUpdateStatus(state) {
      state.loading = true;
      state.error = null;
      state.statusSuccess = false;
    },
    updateStatusSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.statusSuccess = action.payload.data;
    },
    updateStatusError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
      state.statusSuccess = false;
    },

    submitUpdate(state) {
      state.loading = true;
      state.error = null;
      state.submitSuccess = false;
    },
    updateSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.submitSuccess = action.payload.data;
    },
    updateError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
      state.submitSuccess = false;
    },

    submitDropStation(state) {
      state.loading = true;
      state.error = null;
      state.message = '';
      state.submitSuccess = false;
    },
    submitSuccess(state, action: PayloadAction<GeneralDataResponse>) {
      state.loading = false;
      state.submitSuccess = true;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.dropStationForm = DropStationDetailDefault;
    },
    submitError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.dropStationForm = DropStationDetailDefault;
      state.submitSuccess = false;
    },

    loadDeleteDropStation(state) {
      state.loading = true;
      state.error = null;
      state.message = '';
      state.deleteSuccess = false;
    },
    deleteSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.deleteSuccess = true;
    },
    deleteError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.deleteSuccess = false;
    },

    loadDropStationDetail(state) {
      state.loading = true;
      state.error = null;
      state.data = DropStationDetailResponseDefault;
    },
    detailLoaded(state, action: PayloadAction<DropStationDetailType>) {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
    },
    detailError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
      state.data = DropStationDetailResponseDefault;
    },
    detailReset(state) {
      state.loading = true;
      state.error = null;
      state.data = undefined;
    },
    submitReset(state) {
      state.loading = true;
      state.error = null;
      state.message = '';
      state.dropStationForm = undefined;
      state.submitSuccess = false;
    },

    loadStationList(state) {
      state.loading = true;
      state.error = null;
      state.stations = [];
    },
    listStationLoaded(state, action: PayloadAction<StationType[]>) {
      state.loading = false;
      state.error = null;
      state.stations = action.payload;
    },
    listStationError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
      state.stations = [];
    },
  },
});

export const { actions: DropStationActions } = slice;

export const useDropStationSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: DropStationSaga });
  return { actionsDropStation: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useDropStationSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
