import { formatDateForm } from './date';
import {
  SORT_BY_CS_CONFIRMATION,
  SORT_BY_PICKUP,
  UCO_CLIENT_ONE_TIME_TYPE,
  UCO_CLIENT_ROUTINE_TYPE,
  UCO_NEW_CLIENT,
  UCO_VERIFIED_CLIENT,
} from '../config/global.config';
import { SelectorOptionType } from '../types/UcoPickup';

export const convertPickupDate = (status: string, data: Date) => {
  if (status !== 'sudah') {
    return '-';
  } else {
    return formatDateForm(data);
  }
};

export const statusConverter = (status: string | undefined) => {
  if (status === 'belum') {
    return {
      value: 1,
      label: 'belum',
    };
  } else if (status === 'sudah') {
    return {
      value: 2,
      label: 'sudah',
    };
  } else {
    return {
      value: 3,
      label: 'batal',
    };
  }
};

export const statusReceiptConverter = (status: string | undefined) => {
  if (status === 'belum') {
    return {
      value: 1,
      label: 'belum',
    };
  } else {
    return {
      value: 2,
      label: 'sudah',
    };
  }
};

export const statusDepositConverter = (status: string | undefined) => {
  if (status === 'belum bayar') {
    return {
      value: 1,
      label: 'belum bayar',
    };
  } else if (status === 'sudah bayar') {
    return {
      value: 2,
      label: 'sudah bayar',
    };
  } else {
    return {
      value: 3,
      label: 'batal',
    };
  }
};

export const methodConverter = (method: string | undefined) => {
  if (method === 'Gopay') {
    return {
      value: 'gopay',
      label: 'Gopay',
    };
  } else {
    return {
      value: 'bca',
      label: 'BCA',
    };
  }
};

export const valueToOptions = (value: number) => {
  if (value === 1) {
    return {
      value: 1,
      label: 'belum',
    };
  } else if (value === 2) {
    return {
      value: 2,
      label: 'sudah',
    };
  } else {
    return {
      value: 3,
      label: 'batal',
    };
  }
};

export const valueToOptionsReceipt = (value: number) => {
  if (value === 1) {
    return {
      value: 1,
      label: 'belum',
    };
  } else {
    return {
      value: 2,
      label: 'sudah',
    };
  }
};

export const valueToOptionsDeposit = (value: number) => {
  if (value === 1) {
    return {
      value: 1,
      label: 'belum bayar',
    };
  } else if (value === 2) {
    return {
      value: 2,
      label: 'sudah bayar',
    };
  } else {
    return {
      value: 3,
      label: 'batal',
    };
  }
};

export const valueToOptionsMethod = (value: string) => {
  if (value === 'gopay') {
    return {
      value: 'gopay',
      label: 'Gopay',
    };
  } else {
    return {
      value: 'bca',
      label: 'BCA',
    };
  }
};

export const valueToOptionWHMConfirmation = (
  value: number,
  data: SelectorOptionType[],
) => {
  const filtered = data.filter(item => item.value === value);
  return filtered[0];
};

export const roundNumber = (value: number) => {
  return Math.round(value);
};

export const isChecked = (id: number, idArr: number[]) => {
  return idArr.some(item => item === id);
};

export const clientTypeConverter = (type: number) => {
  if (type === UCO_CLIENT_ONE_TIME_TYPE.value) {
    return UCO_CLIENT_ONE_TIME_TYPE.label;
  } else {
    return UCO_CLIENT_ROUTINE_TYPE.label;
  }
};

export const clientStatusConverter = (status: number) => {
  if (status === UCO_NEW_CLIENT.value) {
    return UCO_NEW_CLIENT.label;
  } else {
    return UCO_VERIFIED_CLIENT.label;
  }
};

export const selectedSortByConverter = (selectedSortBy: SelectorOptionType) => {
  if (selectedSortBy === SORT_BY_CS_CONFIRMATION) {
    return 'cs_confirmation';
  } else if (selectedSortBy === SORT_BY_PICKUP) {
    return 'pickup';
  } else {
    return 'transfer';
  }
};

export const csAndWHMConfirmationConverter = (
  value: number,
  data: SelectorOptionType[],
) => {
  const filtered = data.filter(item => item.value === value);
  return filtered[0].label;
};
