/**
 *
 * Asynchronously loads the component for Banner
 *
 */

import { lazyLoad } from 'utils/loadable';

export const UcoPickupRequest = lazyLoad(
    () => import('./index'),
    module => module.UcoPickupRequest,
);

export const UcoPickupEditData = lazyLoad(
    () => import('./edit-data'),
    module => module.UcoPickupEditData,
);

export const UcoPickupEditTransfer = lazyLoad(
    () => import('./edit-transfer'),
    module => module.UcoPickupEditTransfer,
);
