/**
 *
 * Asynchronously loads the component for Banner
 *
 */

import { lazyLoad } from 'utils/loadable';

export const DropStation = lazyLoad(
  () => import('./index'),
  module => module.DropStation,
);

export const DropStationDetail = lazyLoad(
  () => import('./details'),
  module => module.DropStationDetail,
);

export const DropStationForm = lazyLoad(
    () => import('./form'),
    module => module.DropStationForm,
);
