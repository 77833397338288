import {
  ACCOUNT_STATUS,
  ACCOUNT_STATUS_OPTIONS,
  ACTIVE_ACCOUNT_STATUS,
} from '../config/global.config';
import { OptionType, SelectorOptionType, RoleType } from 'types';

export const isEmptyString = (data: string | undefined | null) => {
  if (!data) return true;
  return data === '';
};

export const isUndefined = (data: any) => {
  return data === undefined;
};

export const convertStatusToSelector = (data: boolean) => {
  if (data) {
    return ACCOUNT_STATUS[1];
  } else {
    return ACCOUNT_STATUS[2];
  }
};

export const convertSelectorToBoolean = (data: SelectorOptionType) => {
  return data.value === ACCOUNT_STATUS[ACTIVE_ACCOUNT_STATUS].value;
};

export const convertStatus = (data: boolean) => {
  if (data) {
    return ACCOUNT_STATUS_OPTIONS[0];
  } else {
    return ACCOUNT_STATUS_OPTIONS[1];
  }
};

export const convertStatusToBoolean = (data: OptionType | undefined) => {
  if (data) {
    return data.key === ACCOUNT_STATUS_OPTIONS[0].key;
  } else {
    return false;
  }
};

export const randomString = (length: number, chars: string) => {
  let mask = '';
  if (chars.indexOf('a') > -1) mask += 'abcdefghjklmnopqrstuvwxyz';
  if (chars.indexOf('A') > -1) mask += 'ABCDEFGHJKLMNOPQRSTUVWXYZ';
  if (chars.indexOf('#') > -1) mask += '0123456789';
  if (chars.indexOf('!') > -1) mask += '!@#';
  let result = '';
  for (let i = length; i > 0; --i)
    result += mask[Math.floor(Math.random() * mask.length)];
  return result;
};

export const convertRoleToOptions = (role: RoleType[]) => {
  let options: OptionType[] = [];
  role.forEach(item => {
    let temp: OptionType = {
      key: item?.id,
      optionText: item?.name,
    };
    options.push(temp);
  });
  return options;
};

export const acronym = (data: string) => {
  if (isEmptyString(data)) {
    return '';
  } else {
    let matches = data.match(/\b(\w)/g);
    // @ts-ignore
    let acronym = matches.join('');
    if (acronym.length === 1) {
      return acronym.toUpperCase() + acronym.toUpperCase();
    } else {
      return acronym.slice(0, 2).toUpperCase();
    }
  }
};
