import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { TextInput } from '../TextInput';
import { IModalTopUpProps } from './modal-topup.props';
import {
  BALANCE_AFTER_TOP_UP,
  CURRENT_BALANCE,
  RUPIAH_CURRENCY,
} from '../../pages/Withdraw/constants';

export const ModalTopUp: React.FC<IModalTopUpProps> = props => {
  const {
    show,
    close,
    isEdit,
    title,
    body,
    currentAmount,
    positiveText,
    negativeText,
    setAmount,
    amount,
    onPositiveClick,
    onNegativeClick,
  } = props;

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={close}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="w-96 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:p-6">
              <button onClick={close}>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <Dialog.Title
                as="h3"
                className="text-2xl leading-6 font-medium text-gray-900 my-4"
              >
                {title}
              </Dialog.Title>
              <TextInput
                label={body}
                type={'top-label'}
                id={'amount'}
                formType={'number'}
                onChange={e => setAmount(Number(e.target.value))}
                value={amount}
              />
              <div className="my-6">
                {!isEdit ? (
                  <div className="flex flex-col">
                    <h5 className="text-sm mb-2">
                      {`${CURRENT_BALANCE} `}
                      <span className="font-medium">
                        {`${RUPIAH_CURRENCY}${currentAmount}`}
                      </span>
                    </h5>
                    <h5 className="text-sm">
                      {`${BALANCE_AFTER_TOP_UP} `}
                      <span className="font-medium">
                        {`${RUPIAH_CURRENCY}${currentAmount + amount}`}
                      </span>
                    </h5>
                  </div>
                ) : null}
              </div>
              <div className="sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 sm:ml-3 sm:w-auto"
                  onClick={() => onPositiveClick()}
                  disabled={!isEdit && amount === 0}
                >
                  {positiveText}
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={() => onNegativeClick()}
                  ref={cancelButtonRef}
                >
                  {negativeText}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
