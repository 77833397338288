/**
 *
 * Asynchronously loads the component for Banner
 *
 */

import { lazyLoad } from 'utils/loadable';

export const FraudManagement = lazyLoad(
  () => import('./index'),
  module => module.FraudManagement,
);

export const FraudManagementDetail = lazyLoad(
  () => import('./fraud-detail'),
  module => module.FraudDetail,
);
