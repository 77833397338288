/**
 *
 * Banner List (index)
 *
 */
import React, { Fragment, useEffect, useState } from 'react';
import { MainNavigator } from '../../../navigators/mainNavigator';

import { DotsVerticalIcon, PlusIcon } from '@heroicons/react/solid';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { classNames } from '../../../utils/classNames';
import { Menu, Transition } from '@headlessui/react';
import { Button } from '../../components/Button';
import { ModalConfirm } from '../../components/ModalConfirm';
import { LoadingTable } from '../../components/LoadingTable';
import DataTable from 'react-data-table-component';
import { useBannerSlice } from '../../modules/banner/slice';
import {
  selectBannerList,
  selectBannerLoading,
  selectDeleteBannerSuccess,
} from '../../modules/banner/slice/selectors';
import { BannerType } from '../../../types/Banner';

interface Props {
  title?: string;
}

export const Banner = (props: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const bannerList = useSelector(selectBannerList);
  const isDeleted = useSelector(selectDeleteBannerSuccess);
  const loading = useSelector(selectBannerLoading);

  const { actionsBanner } = useBannerSlice();

  const [data, setData] = useState<BannerType[]>([]);

  useEffect(() => {
    if (bannerList) {
      setData(bannerList);
    }
  }, [bannerList]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(actionsBanner.loadBannerList());
    }, 1000);
  }, []);

  useEffect(() => {
    if (isDeleted) {
      dispatch(actionsBanner.submitReset());
    }
  }, [isDeleted]);

  const Content = () => {
    const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDeletePress = async () => {
      await dispatch(actionsBanner.setSelectedBannerID(selectedId ?? 0));
      await dispatch(actionsBanner.loadDeleteBanner());
      const updatedData = data.filter(d => d.id !== selectedId);
      setData(updatedData);
      setSelectedId(undefined);
      setShowDeleteModal(false);
    };

    const COLUMNS = [
      {
        id: 'name',
        name: 'Nama',
        selector: row => row.name,
        cell: row => (
          <div className={'whitespace-nowrap text-sm text-gray-500'}>
            <button onClick={() => history.push('/banner/detail/' + row?.id)}>
              {row.name}
            </button>
          </div>
        ),
        sortable: true,
        sortField: 'name',
      },
      {
        name: 'Link',
        selector: row => row?.action,
      },
      {
        id: 'sequence',
        name: 'Urutan',
        selector: row => row.sequence,
        sortable: true,
        sortField: 'sequence',
      },
      {
        name: 'Gambar',
        selector: row => row.imageUrl,
        cell: row => (
          <div className={'whitespace-nowrap text-sm text-gray-500 py-4 mr-5'}>
            <img
              className="object-cover shadow-lg rounded-lg"
              style={{ maxHeight: 200, maxWidth: 250 }}
              src={row.imageUrl}
              alt=""
            />
          </div>
        ),
      },
      {
        name: 'Actions',
        selector: row => row.id,
        grow: 0,
        cell: item => (
          <div className={'p-1'}>
            <Menu as="div" className="flex-shrink-0 pr-2">
              <Menu.Button className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                <span className="sr-only">Open options</span>
                <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-10 mx-3 origin-top-right absolute right-10 top-3 w-48 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={'/banner/' + item.id + '/edit'}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm text-center',
                          )}
                        >
                          Edit
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            setSelectedId(item.id);
                            setShowDeleteModal(true);
                          }}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full',
                          )}
                        >
                          Delete
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        ),
      },
    ];

    return (
      <div className="overflow-hidden md:rounded-lg mb-5 p-5">
        <DataTable
          columns={COLUMNS}
          dense
          striped
          fixedHeader
          progressComponent={<LoadingTable />}
          progressPending={loading}
          data={data}
        />
        <ModalConfirm
          title={'Delete Banner'}
          body={'apa anda yakin ingin mendelete banner ini?'}
          onPositiveClick={handleDeletePress}
          onNegativeClick={() => setShowDeleteModal(false)}
          positiveText={'Delete'}
          negativeText={'Cancel'}
          show={showDeleteModal}
          isPositiveCase={false}
        />
        <div className="pt-5"></div>
      </div>
    );
  };

  return (
    <MainNavigator
      content={<Content />}
      title={props.title}
      topButton={
        <Button
          onClick={() => history.push('/banner/create')}
          type={'primary'}
          icon={
            <PlusIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
          }
        >
          Buat Banner
        </Button>
      }
    />
  );
};
