import { format, endOfMonth, startOfMonth, parse } from 'date-fns';

export function getMonthDates(monthYear: string) {
  const parsedDate = parse(monthYear, 'MM/yyyy', new Date());

  // Get the start of the month
  const fromDate = startOfMonth(parsedDate);

  // Get the end of the month
  const toDate = endOfMonth(parsedDate);

  // Format dates to yyyy-mm-dd format
  const formattedFromDate = format(fromDate, 'yyyy-MM-dd');
  const formattedToDate = format(toDate, 'yyyy-MM-dd');

  return { fromDate: formattedFromDate, toDate: formattedToDate };
}
