import { useState } from 'react';
import { IModalMapProps } from './modal-map.props';

export const useModalMap = ({
  position = {
    lat: -6.1753871,
    lng: 106.8249641,
  },
  zooms = 16,
}: IModalMapProps) => {
  const [defaultLocation, setDefaultLocation] = useState(position);

  const [location, setLocation] = useState(position);
  const [zoom, setZoom] = useState(zooms);

  const handleChangeLocation = (lat, lng) =>
    setLocation({ lat: lat, lng: lng });

  const handleChangeZoom = newZoom => setZoom(newZoom);

  return {
    defaultLocation,
    location,
    zoom,
    setDefaultLocation,
    handleChangeLocation,
    handleChangeZoom,
  };
};
