import React from 'react';
import {
  EyeIcon,
  CheckIcon as CheckSolid,
  PencilIcon,
  TrashIcon,
  XIcon as XSolid,
} from '@heroicons/react/solid';
import { BellIcon } from '@heroicons/react/outline';
import { classNames } from '../../../utils/classNames';

const DEFAULT_STYLE = 'h-5 w-5 text-blue-500';

export const NotificationIcon = ({ className }: { className?: string }) => (
  <BellIcon className={classNames(className, DEFAULT_STYLE)} />
);

export const DeleteIcon = ({ className }: { className?: string }) => (
  <TrashIcon className={classNames(className, DEFAULT_STYLE, 'text-red-500')} />
);

export const ViewIcon = ({ className }: { className?: string }) => (
  <EyeIcon className={classNames(className, DEFAULT_STYLE)} />
);

export const EditIcon = ({ className }: { className?: string }) => (
  <PencilIcon className={classNames(className, DEFAULT_STYLE)} />
);

export const CheckIcon = ({ className }: { className?: string }) => (
  <CheckSolid className={classNames(className, DEFAULT_STYLE)} />
);

export const XIcon = ({ className }: { className?: string }) => (
  <XSolid className={classNames(className, DEFAULT_STYLE)} />
);
