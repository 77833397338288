/**
 *
 * Asynchronously loads the component for Banner
 *
 */

import { lazyLoad } from 'utils/loadable';

export const UcoClient = lazyLoad(
    () => import('./index'),
    module => module.UcoClient,
);

export const UcoClientDetail = lazyLoad(
    () => import('./edit-data'),
    module => module.UcoClientDetail,
);
