import React from 'react';
import { IPaginationProps } from './pagination.props';

const Pagination: React.FC<IPaginationProps> = props => {
  if (!props.show || props.dataLength === 0) return <></>;
  return (
    <nav
      className="bg-white px-4 py-3 flex items-center justify-between sm:px-6 border-b"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{props.dataLength}</span> to{' '}
          <span className="font-medium">{props.dataLength}</span> of{' '}
          <span className="font-medium">{props.totalElement}</span> results
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        {props.totalPage > 1 && props.currentPage !== 1 && (
          <button
            onClick={() => props.handlePageChange(props.prevPage)}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </button>
        )}
        {props.totalPage > 1 && props.currentPage !== props.totalPage && (
          <button
            onClick={() => props.handlePageChange(props.nextPage)}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </button>
        )}
      </div>
    </nav>
  );
};

export { Pagination };
