import React from 'react';
import DatePicker from 'react-datepicker';
import { IDatePickerProps } from './datepicker.props';

export const Datepicker: React.FC<IDatePickerProps> = props => {
  return (
    <div>
      <label
        htmlFor="pickupDate"
        className="block text-sm font-medium text-gray-700"
      >
        {props.label}
      </label>
      <DatePicker
        id={props?.id}
        selected={props.value}
        onChange={e => {
          if (props.onChange) {
            props.onChange(e);
          }
        }}
        disabled={props.disabled}
        showTimeSelect={props.showTimeSelect}
        showTimeSelectOnly={props.showTimeSelectOnly}
        timeIntervals={props.timeIntervals}
        timeCaption={props.timeCaption}
        dateFormat={props.dateFormat}
        timeFormat={props.timeFormat}
      />
    </div>
  );
};
