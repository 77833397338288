import React from 'react';
import { checkImagePath } from 'utils';
import { ICardItemProps } from './card-item.props';

export const CardItem: React.FC<ICardItemProps> = props => {
  let CONTENT = props.content ? props.content : '-';
  return (
    <div className="mb-4">
      <dt className="text-sm text-gray-500">{props.title}</dt>
      {props.type === 'image' ? (
        <img
          className="object-cover shadow-lg rounded-lg border-2 w-40 h-60 mt-2"
          src={checkImagePath(props.imagePath)}
          alt="image-card-item"
        />
      ) : (
        <dd className="mt-1 text-sm font-medium  text-gray-900">{CONTENT}</dd>
      )}
    </div>
  );
};
