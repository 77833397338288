/**
 *
 * Asynchronously loads the component for Banner
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ProductList = lazyLoad(
  () => import('./index'),
  module => module.ProductList,
);

export const ProductForm = lazyLoad(
    () => import('./form'),
    module => module.ProductForm,
);

export const ProductDetail = lazyLoad(
    () => import('./details'),
    module => module.ProductDetail,
);

