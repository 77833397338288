import { Pagination, PaginationParams } from '../../../types/Pagination';
import { SortOptions } from '../../components/SortFilter';

export const PAGINATION_LIMIT = 10;
export const paginationParamsDefault: PaginationParams = {
  limit: PAGINATION_LIMIT,
  page: 1,
  order: SortOptions.ASC,
};
export const paginationDefault: Pagination = {
  limit: 10,
  currentPage: 1,
  sort: 'asc',
  totalElement: 0,
  totalPage: 0,
  nextPage: 2,
  prevPage: 1,
};
