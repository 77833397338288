import React from 'react';
import { ClockIcon } from '@heroicons/react/outline';
import { ILastestUpdateProps } from './lastest-update.props';

export const LatestUpdate: React.FC<ILastestUpdateProps> = props => {
  const { updatedBy, updatedAt, isShow = false } = props;
  // hidden are temporary, until further discussion
  if (isShow)
    return (
      <div className={'flex flex-row'}>
        <ClockIcon className="mr-1 h-5 w-5" aria-hidden="true" />
        <span className={'text-sm font-extralight'}>
          Updated
          <span className={'font-medium'}> {updatedAt} </span>
          by <span className={'font-medium'}> {updatedBy} </span>
        </span>
      </div>
    );
  return null;
};
