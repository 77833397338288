import { useState } from 'react';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../api/api.types';

export default function useToken() {
  const getAccessToken = () => {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  };

  const getRefreshToken = () => {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  };

  const [token, setToken] = useState<string | null>(getAccessToken());
  const [refreshToken, setRefreshToken] = useState<string | null>(
    getRefreshToken(),
  );

  const saveToken = (accessToken, refreshToken) => {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    setToken(accessToken);
    setRefreshToken(refreshToken);
  };

  const clearToken = () => {
    localStorage.clear();
    sessionStorage.clear();
    setToken(null);
    setRefreshToken(null);
  };

  return {
    setToken: saveToken,
    clearToken,
    token,
    refreshToken,
  };
}
