export function maxText(text: string) {
  if (text.length < 30) {
    return text;
  }
  return `${text.slice(0, 29)}...`;
}

export function maxTextWithNumber(text: string, num: number) {
  if (text.length < num) {
    return text;
  }
  return `${text.slice(0, num)}...`;
}
