import React from 'react';
import { ITabButtonProps } from './tab-button.props';
import { classNames } from 'utils';

export const TabButton = ({
  tab,
  active = false,
  onPress,
  amount,
}: ITabButtonProps) => {
  const textColor = (isActive: boolean) => {
    if (isActive) return 'text-celurenBlue-500';
    else return 'text-beauBlue-600';
  };

  const borderColor = (isActive: boolean) => {
    if (isActive) return 'border-b-2 border-celurenBlue-500';
    else return '';
  };

  const displayNumber = (num: number) => {
    return num > 999 ? '+999' : num.toString();
  };

  return (
    <li className="me-2">
      <button
        className={classNames(
          'inline-block p-4 font-medium',
          textColor(active),
          borderColor(active),
        )}
        onClick={onPress}
      >
        {tab}
        {amount && amount > 0 ? (
          <span className="rounded-full bg-celurenBlue-50 px-2 py-1 mx-2">
            {displayNumber(amount)}
          </span>
        ) : null}
      </button>
    </li>
  );
};
