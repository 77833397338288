import React from 'react';
import { useEffect, useState } from 'react';
import { ISelectProps } from './select.props';
import { SelectDataItem } from '.';

export const Select: React.FC<ISelectProps> = props => {
  const {
    label,
    data = DATA_EXAMPLE_SELECT,
    onSelect = (selected: SelectDataItem) => null,
    value,
  } = props;

  const [selected, setSelected] = useState<SelectDataItem | null>(null);

  useEffect(() => {
    if (selected) onSelect(selected);
  }, [selected]);

  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor="username"
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {label}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="max-w-lg flex rounded-md shadow-sm">
          <select
            id="location"
            name="location"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            defaultValue={value}
            onChange={e => {
              setSelected(data[e.target.selectedIndex]);
            }}
          >
            {data.map((item, index) => {
              return <option value={item.id}>{item.name}</option>;
            })}
          </select>
        </div>
      </div>
    </div>
  );
};

export const DATA_EXAMPLE_SELECT: SelectDataItem[] = [
  { id: 1, name: 'Wade Cooper' },
  { id: 2, name: 'Arlene Mccoy' },
  { id: 3, name: 'Devon Webb' },
  { id: 4, name: 'Tom Cook' },
  { id: 5, name: 'Tanya Fox' },
  { id: 6, name: 'Hellen Schmidt' },
  { id: 7, name: 'Caroline Schultz' },
  { id: 8, name: 'Mason Heaney' },
  { id: 9, name: 'Claudie Smitham' },
  { id: 10, name: 'Emil Schaefer' },
];

export const DATA_EXAMPLE_SELECT_2: SelectDataItem[] = [
  { id: 1, name: 'Wade Cooper' },
  { id: 2, name: 'Arlene Mccoy' },
];
