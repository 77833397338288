import React from 'react';
import { PaperClipIcon } from '@heroicons/react/solid';

export const BorderWrapper = ({ children }) => (
  <div className="mt-5 border-t border-gray-200">
    <dl className="sm:divide-y sm:divide-gray-200">{children}</dl>
  </div>
);

export const MainWrapper = ({ children }) => (
  <div className={'justify-center items-center max-w-4xl mx-auto mt-8 pb-64'}>
    {children}
  </div>
);

export const Section = ({
  title = 'Applicant Information',
  description = ' Personal details and application.',
  className = '',
  button = <></>,
}) => (
  <div className={className}>
    <div className={'flex justify-between items-center'}>
      <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
      {button}
    </div>
    <p className="mt-1 max-w-2xl text-sm text-gray-500">{description}</p>
  </div>
);

export const RowData = ({
  label = 'Full name',
  data = 'Margot Foster',
}: {
  label: string;
  data: string | number | undefined | any;
}) => (
  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
    <dt className="text-sm font-medium text-gray-500">{label}</dt>
    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data}</dd>
  </div>
);

export type AttachmentFileItem = {
  id: number;
  filename: string;
  link: string;
};

const ATTACHMENT_LIST_EXAMPLE: AttachmentFileItem[] = [
  {
    id: 0,
    filename: 'ktp_joni.jpeg',
    link: 'https://cdn.idntimes.com/content-images/community/2021/06/photogridlite-1622790177340-43cfb48a7c6e129efedbed9719f86352-dd0a3bf1661970c9e4e9758125d22bf9_600x400.jpg',
  },
  {
    id: 1,
    filename: 'SK.jpeg',
    link: 'https://cdn.idntimes.com/content-images/community/2021/06/photogridlite-1622790177340-43cfb48a7c6e129efedbed9719f86352-dd0a3bf1661970c9e4e9758125d22bf9_600x400.jpg',
  },
];

export const RowDataAttachment = ({
  label = 'Attachments',
  fileList = ATTACHMENT_LIST_EXAMPLE,
}) => (
  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
    <dt className="text-sm font-medium text-gray-500">{label}</dt>
    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
      <ul
        role="list"
        className="border border-gray-200 rounded-md divide-y divide-gray-200"
      >
        {fileList.map(({ id, filename, link }) => {
          return (
            <li
              className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
              key={id}
            >
              <div className="w-0 flex-1 flex items-center">
                <img
                  alt={filename}
                  src={link}
                  style={{
                    height: '180px',
                    aspectRatio: '4/3',
                    objectFit: 'cover',
                  }}
                  className={'rounded-lg border-2 border-cyan-600 mr-4'}
                />
                <div className="flex flex-1 items-center">
                  <PaperClipIcon
                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-2 flex-1 w-0 truncate">{filename}</span>
                </div>
              </div>
              <div className="ml-4 flex-shrink-0">
                <a
                  href={link}
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Download
                </a>
              </div>
            </li>
          );
        })}
      </ul>
    </dd>
  </div>
);

export const DetailsComponentExample = () => {
  return (
    <MainWrapper>
      <Section />
      <BorderWrapper>
        <RowData label={'sss'} data={'sss'} />
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium text-gray-500">Application for</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            Backend Developer
          </dd>
        </div>
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium text-gray-500">Email address</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            margotfoster@example.com
          </dd>
        </div>
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium text-gray-500">
            Salary expectation
          </dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            $120,000
          </dd>
        </div>
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="text-sm font-medium text-gray-500">About</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim
            incididunt cillum culpa consequat. Excepteur qui ipsum aliquip
            consequat sint. Sit id mollit nulla mollit nostrud in ea officia
            proident. Irure nostrud pariatur mollit ad adipisicing reprehenderit
            deserunt qui eu.
          </dd>
        </div>
        <RowDataAttachment />
      </BorderWrapper>
    </MainWrapper>
  );
};
