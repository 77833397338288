import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { authSaga } from './saga';
import { ProfileState, LoginData } from './types';
import {
  AccountListType,
  EditAccountFormType,
  User,
} from '../../../../types/User';
import { GeneralDataResponse } from '../../../../api/api.types';
import { Pagination, PaginationParams } from '../../../../types/Pagination';
import { paginationDefault, paginationParamsDefault } from '../../pagination';

const defaultUserData: User = {
  accessUuid: '',
  userId: 0,
  username: '',
  name: 'testing',
  whatsapp: '',
  level: '',
  accessToken: '',
  refreshToken: '',
};

export const accountDetailDefault: AccountListType = {
  id: 0,
  createdAt: '',
  name: '',
  roleName: '',
  username: '',
  email: '',
  password: '',
  roleId: 0,
  isActive: false,
  updatedAt: '',
};

export const formEditAccountDefault: EditAccountFormType = {
  name: '',
  email: '',
  password: '',
  isActive: false,
  roleId: 0,
};

export const initialState: ProfileState = {
  userData: defaultUserData,
  refreshToken: '',
  selectedUserId: '',
  loading: false,
  loginData: null,
  errors: null,
  message: '',
  accountList: [],
  accountListByStatus: [],
  paginationParams: paginationParamsDefault,
  pagination: paginationDefault,
  profileId: 0,
  selectedAccountId: 0,
  accountDetail: accountDetailDefault,
  profileDetail: accountDetailDefault,
  formEditAccount: formEditAccountDefault,
  addAccount: formEditAccountDefault,
  role: [],
  selectedDeleteAccountId: 0,
  submitSuccess: false,
  error: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoginData(state, action: PayloadAction<LoginData>) {
      state.loginData = action.payload;
    },
    setRefreshTokenData(state, action: PayloadAction<string>) {
      state.refreshToken = action.payload;
    },
    loadUserData(state) {
      state.loading = true;
      state.userData = defaultUserData;
    },
    loadRefreshToken(state) {
      state.loading = true;
    },
    setUserData(state, action: PayloadAction<User>) {
      state.loading = false;
      state.userData = action.payload;
    },
    loadRefreshTokenFailed(state, action: PayloadAction<GeneralDataResponse>) {
      state.loading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },
    loginFailed(state, action: PayloadAction<GeneralDataResponse>) {
      state.loading = false;
      state.userData = defaultUserData;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },

    // Account management
    setPaginationParams(state, action: PayloadAction<PaginationParams>) {
      state.paginationParams = action.payload;
    },
    setAccountList(state, action: PayloadAction<AccountListType[]>) {
      state.accountList = action.payload;
    },
    loadAccountList(state) {
      state.loading = true;
      state.error = null;
      state.accountList = [];
    },
    accountListLoaded(
      state,
      action: PayloadAction<{
        pagination: Pagination;
        data: AccountListType[];
      }>,
    ) {
      state.loading = false;
      state.error = null;
      state.pagination = action.payload.pagination;
      state.accountList = action.payload.data;
    },
    accountListError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
      state.accountList = [];
    },

    loadAccountListSearch(state) {
      state.loading = true;
      state.error = null;
      state.accountList = [];
    },

    loadAccountListByStatus(state) {
      state.loading = true;
      state.error = null;
      state.accountListByStatus = [];
    },
    accountListByStatusLoaded(
      state,
      action: PayloadAction<{
        pagination: Pagination;
        data: AccountListType[];
      }>,
    ) {
      state.loading = false;
      state.error = null;
      state.pagination = action.payload.pagination;
      state.accountListByStatus = action.payload.data;
    },
    accountListByStatusError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
      state.accountListByStatus = [];
    },

    setAccountId(state, action: PayloadAction<any>) {
      state.selectedAccountId = action.payload;
    },
    loadAccountDetail(state) {
      state.loading = true;
      state.error = null;
      state.accountDetail = accountDetailDefault;
    },
    accountDetailLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.accountDetail = action.payload.data;
    },
    accountDetailError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
      state.accountDetail = accountDetailDefault;
    },

    setProfileId(state, action: PayloadAction<any>) {
      state.profileId = action.payload;
    },
    loadProfileDetail(state) {
      state.loading = true;
      state.error = null;
      state.profileDetail = accountDetailDefault;
    },
    profileDetailLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.profileDetail = action.payload.data;
    },
    profileDetailError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
      state.profileDetail = accountDetailDefault;
    },

    submitEditAccount(state, action: PayloadAction<EditAccountFormType>) {
      state.loading = true;
      state.error = null;
      state.message = '';
      state.submitSuccess = false;
      state.formEditAccount = action.payload;
    },
    submitEditAccountSuccess(
      state,
      action: PayloadAction<GeneralDataResponse>,
    ) {
      state.loading = false;
      state.submitSuccess = true;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.formEditAccount = formEditAccountDefault;
    },
    submitEditAccountError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.formEditAccount = formEditAccountDefault;
      state.submitSuccess = false;
    },
    submitEditAccountReset(state) {
      state.loading = false;
      state.error = null;
      state.message = '';
      state.formEditAccount = formEditAccountDefault;
      state.submitSuccess = false;
    },

    submitAddAccount(state, action: PayloadAction<EditAccountFormType>) {
      state.loading = true;
      state.error = null;
      state.message = '';
      state.submitSuccess = false;
      state.addAccount = action.payload;
    },
    submitAddAccountSuccess(state, action: PayloadAction<GeneralDataResponse>) {
      state.loading = false;
      state.submitSuccess = true;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.addAccount = formEditAccountDefault;
    },
    submitAddAccountError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.addAccount = formEditAccountDefault;
      state.submitSuccess = false;
    },
    submitAddAccountReset(state) {
      state.loading = false;
      state.error = null;
      state.message = '';
      state.addAccount = formEditAccountDefault;
      state.submitSuccess = false;
    },

    submitDeleteAccount(state, action: PayloadAction<number>) {
      state.loading = true;
      state.error = null;
      state.message = '';
      state.submitSuccess = false;
      state.selectedDeleteAccountId = action.payload;
    },
    submitDeleteAccountSuccess(
      state,
      action: PayloadAction<GeneralDataResponse>,
    ) {
      state.loading = false;
      state.submitSuccess = true;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.selectedDeleteAccountId = 0;
    },
    submitDeleteAccountError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.errors;
      state.message = action.payload.message;
      state.selectedDeleteAccountId = 0;
      state.submitSuccess = false;
    },
    submitDeleteAccountReset(state) {
      state.loading = false;
      state.error = null;
      state.message = '';
      state.selectedDeleteAccountId = 0;
      state.submitSuccess = false;
    },

    loadRole(state) {
      state.loading = true;
      state.error = null;
      state.role = [];
    },
    roleLoaded(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = null;
      state.role = action.payload.data;
    },
    roleError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload.error;
      state.role = [];
    },
  },
});

export const { actions: profileActions } = slice;

export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: authSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAuthSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
