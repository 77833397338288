import { ApiResponse, ApisauceInstance, create } from 'apisauce';
import { getGeneralApiProblem } from './api-problem';
import { ApiConfig, DEFAULT_API_CONFIG, UCO_API_CONFIG } from './api-config';
import { logger } from 'utils/logger';
import * as Types from './api.types';
import {
  CHECK_PASSWORD,
  GeneralApiResult,
  REFRESH_TOKEN_ADMIN,
} from './api.types';
import { responseMonitor } from './api-monitor';
import { ApiKind, ApiMethod } from '../config/global.config';
import { EMPTY_STRING, StatusCodeHttp } from '../constants';
import { isEmptyString } from 'utils';

/**
 * Logger
 */
const log = logger().child({ module: 'MainApi' });

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  // @ts-ignore
  apisauce: ApisauceInstance;
  // @ts-ignore
  apisauceUco: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;
  configUco: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG || UCO_API_CONFIG) {
    this.config = DEFAULT_API_CONFIG;
    this.configUco = UCO_API_CONFIG;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    log.info('Setup Api');
    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json',
      },
    });

    this.apisauce.addMonitor(responseMonitor);
    // Override the original request method
    this.apisauce.addResponseTransform(response => {
      // Check if the response status code is 401
      const refreshTokenLocal =
        localStorage?.getItem(Types.REFRESH_TOKEN_KEY) ?? EMPTY_STRING;
      if (
        response.status === StatusCodeHttp.UNAUTHORIZED &&
        !isEmptyString(refreshTokenLocal)
      ) {
        // Handle the 401 response, e.g., trigger a token refresh or redirect to log in
        this.requestNewToken(refreshTokenLocal);

        // You might want to refresh the access token or redirect to the login screen
        // For simplicity, let's assume you have a function refreshToken() for token refresh
      }

      // Return the original response
      return response;
    });
  }

  setupUco() {
    log.info('Setup Api Uco');
    // construct the apisauce UCO instance
    this.apisauceUco = create({
      baseURL: this.configUco.url,
      timeout: this.configUco.timeout,
      headers: {
        Accept: 'application/json',
      },
    });
    this.apisauceUco.addMonitor(responseMonitor);
  }

  setToken(token: string) {
    log.info('TOKEN set 🚀 : ' + token);
    this.apisauce.setHeader('Authorization', `Bearer ${token}`);
    this.apisauceUco.setHeader('Authorization', `Bearer ${token}`);
  }

  removeToken() {
    log.info('TOKEN removed 🔥');
    this.apisauce.deleteHeader('Authorization');
    this.apisauceUco.deleteHeader('Authorization');
  }

  async generalApi(
    method: ApiMethod,
    isMultipart: boolean,
    url: string,
    isUco: boolean,
    ...args
  ): Promise<GeneralApiResult> {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    log.debug(
      'General Api with method',
      method,
      ', url: ',
      url,
      ', is multipart: ',
      isMultipart,
      ', arg: ',
      args,
    );

    let response: ApiResponse<any> | undefined = undefined;
    let api = this.apisauce;
    if (isUco) api = this.apisauceUco;
    if (method === ApiMethod.POST && !isMultipart)
      response = await api.post(url, ...args);
    else if (method === ApiMethod.POST && isMultipart)
      response = await api.post(url, ...args, { headers });
    else if (method === ApiMethod.PUT && !isMultipart)
      response = await api.put(url, ...args);
    else if (method === ApiMethod.PUT && isMultipart)
      response = await api.put(url, ...args, { headers });
    else if (method === ApiMethod.DELETE)
      response = await api.delete(url, ...args);
    else response = await api.get(url, ...args);

    if (response && !response.ok) getGeneralApiProblem(response);

    log.debug('General Api Response', response?.data);

    try {
      return { kind: ApiKind.OK, data: response?.data };
    } catch {
      return { kind: ApiKind.BAD_DATA };
    }
  }

  /**
   * Verify password
   */
  async verifyPassword(password: string): Promise<boolean> {
    const api = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json',
        Authorization: `${localStorage.getItem(Types.ACCESS_TOKEN_KEY) ?? ''}`,
      },
    });

    const response: ApiResponse<any> = await api.post(CHECK_PASSWORD, {
      password,
    });
    if (response.ok) return response.data.data;
    return false;
  }

  /**
   * Refresh Token
   */
  async requestNewToken(refreshToken: string): Promise<any> {
    // make the api call
    const response: ApiResponse<any> = await this.apisauce.post(
      REFRESH_TOKEN_ADMIN,
      {
        refreshToken,
      },
    );

    // force logout if refresh token get response failed
    if (!response.ok) {
      window.location.assign('/logout');
    } else {
      // transform the data into the format we are expecting
      try {
        const accessToken = response?.data?.data?.accessToken;
        const refreshToken = response?.data?.data?.refreshToken;
        localStorage.setItem(Types.ACCESS_TOKEN_KEY, accessToken);
        localStorage.setItem(Types.REFRESH_TOKEN_KEY, refreshToken);
        return { kind: 'ok', data: response.data.data };
      } catch {
        return { kind: 'bad-data' };
      }
    }
  }
}
