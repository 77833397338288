/**
 *
 * Asynchronously loads the component for Banner
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AccountList = lazyLoad(
  () => import('./index'),
  module => module.AccountList,
);

export const EditAccount = lazyLoad(
  () => import('./edit-account'),
  module => module.UcoPickupEditAccount,
);
