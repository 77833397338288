import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { XCircleIcon } from '@heroicons/react/outline';
import { classNames } from 'utils';
import { DEFAULT_DELAY } from '../../../config/env.config';
import { IToolTipProps } from './tooltip.props';

const style = { borderRadius: 8, zIndex: 9999 };

export const ToolTip = ({
  id,
  containerClassName,
  title = '',
  titleClassName,
  desc = '',
  descClassName,
  clickable = false,
  place,
  children,
}: IToolTipProps) => {
  const [hide, setHide] = useState<boolean>(false);

  const handleClose = () => {
    setHide(true);
  };

  useEffect(() => {
    setTimeout(() => {
      if (hide) setHide(!hide);
    }, DEFAULT_DELAY);
  }, [hide]);

  return (
    <Tooltip
      id={id}
      style={style}
      clickable={clickable}
      place={place}
      hidden={hide}
    >
      <div className={containerClassName}>
        <div
          className={classNames(
            'flex w-full',
            !title ? 'justify-end' : undefined,
          )}
        >
          {title ? (
            <div className="flex w-1/2">
              <h3 className={titleClassName}>{title}</h3>
            </div>
          ) : null}
          <div onClick={handleClose} className="flex w-1/2 justify-end">
            <XCircleIcon className="w-5 h-5" />
          </div>
        </div>
        {desc ? <p className={descClassName}>{desc}</p> : null}
        {children}
      </div>
    </Tooltip>
  );
};
