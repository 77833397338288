import { useEffect, useRef, useState } from 'react';
import { IModalProps } from './modal.props';

export const useModal = ({ show = false }: IModalProps) => {
  const [open, setOpen] = useState(show);
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  return { open, cancelButtonRef, setOpen };
};
