import React from 'react';
import { ITabViewProps } from './tab-view.props';
import { TabButton } from './components';

export const TabView = ({
  dataTabs,
  activeTab,
  setActiveTab,
  dataTabAmount,
}: ITabViewProps) => {
  return (
    <div className="text-sm font-medium text-center text-gray-500 border-b border-beauBlue-500 dark:border-beauBlue-500">
      <ul className="flex flex-wrap -mb-px">
        {dataTabs.map((dataTab, i) => {
          return (
            <TabButton
              key={i}
              {...dataTab}
              active={dataTab.id === activeTab.id}
              onPress={() => setActiveTab(dataTab)}
              amount={dataTabAmount?.find(d => d.status === dataTab.tab)?.count}
            />
          );
        })}
      </ul>
    </div>
  );
};
