/**
 *
 * HStack
 *
 */
import * as React from 'react';
import { classNames } from 'utils';
import { IHStackProps } from './hstack.props';

export const HStack: React.FC<IHStackProps> = props => {
  return (
    <div className={classNames('flex flex-col', props.className)}>
      {props.children}
    </div>
  );
};
