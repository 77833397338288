/**
 *
 * Alerts
 *
 */
import * as React from 'react';
import {
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XCircleIcon,
  XIcon,
} from '@heroicons/react/solid';
import { IAlertsProps } from './alerts.props';

export const Alerts: React.FC<IAlertsProps> = props => {
  if (props.type === 'success') {
    return (
      <div className="rounded-md bg-green-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-green-800">{props.title}</p>
            <div className="mt-2 text-sm text-green-700">
              {props.description}
            </div>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                onClick={props.onDismiss}
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (props.type === 'info') {
    return (
      <div className="rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon
              className="h-5 w-5 text-blue-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">{props.title}</p>
            <p className="mt-3 text-sm md:mt-0 md:ml-6">
              <a
                href={props.link}
                className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
              >
                Details <span aria-hidden="true">&rarr;</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (props.type === 'error') {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{props.title}</h3>
            <div className="mt-2 text-sm text-red-700">
              {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
              <ul role="list" className="list-disc pl-5 space-y-1">
                {/*@ts-ignore*/}
                {props.description?.map(item => <li>{item}</li>)}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (props.type === 'warning') {
    return (
      <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              {props.title}
            </h3>
            <div className="mt-2 text-sm text-yellow-700">
              {props.description}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div />;
};
