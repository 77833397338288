import { OptionType, SelectorOptionType } from '../types/UcoPickup';
import { ConfirmationModalType } from '../types/User';

export const RESPONSE_KIND_OK: string = 'ok';
export const FIRST_PAGE = 1;
export const NEXT_PAGE = 1;
export const DEFAULT_LIMIT = 20;
export const LIMIT_10 = 10;
export const DEFAULT_OPTION_LIMIT = 100;

export const REBOX_STATUS_ONLINE = 'Online';
export const REBOX_STATUS_UNDER_MAINTENANCE = 'Under Maintenance';

export const MAX_COMPRESSED_IMAGE_SIZE: number = 1;
export const MAX_COMPRESSED_IMAGE_WIDTH: number = 800;

export const FIRST_ELEMENT: number = 0;
export const GOPAY_EXCHANGE_METHOD_ID: number = 3;
export const BLU_EXCHANGE_METHOD_ID: number = 6;
export const ASTRA_EXCHANGE_METHOD_ID: number = 5;

export const DAY_OPTIONS: OptionType[] = [
  {
    key: '1',
    optionText: 'Senin',
  },
  {
    key: '2',
    optionText: 'Selasa',
  },
  {
    key: '3',
    optionText: 'Rabu',
  },
  {
    key: '4',
    optionText: 'Kamis',
  },
  {
    key: '5',
    optionText: 'Jumat',
  },
  {
    key: '6',
    optionText: 'Sabtu',
  },
  {
    key: '7',
    optionText: 'Minggu',
  },
];

export const CS_CONFIRMATION_OPTION: OptionType[] = [
  {
    optionText: 'Belum',
    key: 0,
  },
  {
    optionText: 'Sudah',
    key: 1,
  },
];

export const UCO_ROUTINE_LIST_ACTION: OptionType[] = [
  {
    key: 'editData',
    optionText: 'Edit Data',
  },
  {
    key: 'editTransfer',
    optionText: 'Edit Transfer',
  },
  {
    key: 'delete',
    optionText: 'Delete',
  },
];

export const UCO_CLIENT_LIST_ACTION: OptionType[] = [
  {
    key: 'editData',
    optionText: 'Edit Data',
  },
  {
    key: 'delete',
    optionText: 'Delete',
  },
];

export const SORT_BY_CS_CONFIRMATION: SelectorOptionType = {
  value: 0,
  label: 'Konfirmasi CS',
};

export const SORT_BY_PICKUP: SelectorOptionType = {
  value: 1,
  label: 'Pickup',
};

export const SORT_BY_TRANSFER: SelectorOptionType = {
  value: 2,
  label: 'Transfer',
};

export const UCO_SELECT_SORT_BY: SelectorOptionType[] = [
  SORT_BY_CS_CONFIRMATION,
  SORT_BY_PICKUP,
  SORT_BY_TRANSFER,
];

export const UCO_PICKUP_STATUS: SelectorOptionType[] = [
  {
    value: 0,
    label: 'Semua',
  },
  {
    value: 1,
    label: 'belum',
  },
  {
    value: 2,
    label: 'sudah',
  },
  {
    value: 3,
    label: 'batal',
  },
];

export const UCO_TRANSFER_STATUS: SelectorOptionType[] = [
  {
    value: 0,
    label: 'Semua',
  },
  {
    value: 1,
    label: 'belum',
  },
  {
    value: 2,
    label: 'sudah',
  },
  {
    value: 3,
    label: 'batal',
  },
];

export const UCO_CS_CONFIRMATION_STATUS: SelectorOptionType[] = [
  {
    value: 2,
    label: 'Semua',
  },
  {
    value: 0,
    label: 'belum',
  },
  {
    value: 1,
    label: 'sudah',
  },
];

export const UCO_WHM_CONFIRMATION_STATUS: SelectorOptionType[] = [
  {
    value: 0,
    label: 'belum',
  },
  {
    value: 1,
    label: 'sudah',
  },
];

export const UCO_NEW_CLIENT = {
  value: 0,
  label: 'Baru',
};

export const UCO_VERIFIED_CLIENT = {
  value: 1,
  label: 'Terverifikasi',
};

export const UCO_ALL_CLIENT = {
  value: 3,
  label: 'Semua',
};

export const UCO_CLIENT_STATUS = [
  UCO_ALL_CLIENT,
  UCO_NEW_CLIENT,
  UCO_VERIFIED_CLIENT,
];

export const UCO_CLIENT_ONE_TIME_TYPE = {
  value: 1,
  label: 'One Time',
};
export const UCO_CLIENT_ROUTINE_TYPE = {
  value: 2,
  label: 'Routine',
};

export const PAGINATION_ROWS_PER_PAGE_OPTIONS = [
  10, 20, 30, 40, 50, 100, 200, 300, 400, 500,
];

// Account Manager
export const ACCOUNT_STATUS_OPTIONS: OptionType[] = [
  {
    key: 0,
    optionText: 'Aktif',
  },
  {
    key: 1,
    optionText: 'Suspend',
  },
];

export const CONFIRM_SAVE_MODAL: ConfirmationModalType = {
  title: 'Konfirmasi Simpan Data',
  body: 'Setelah Anda mengisi atau memperbarui data, pastikan untuk menyimpannya agar perubahan Anda tetap tercatat. Apakah Anda ingin menyimpan data sekarang?',
  positiveText: 'Ya, Simpan',
  negativeText: 'Kembali',
};

export const CONFIRM_NOT_SAVED_MODAL: ConfirmationModalType = {
  title: 'Anda Belum Simpan Data',
  body: 'Tampaknya Anda belum menyimpan perubahan terbaru pada data. Apakah Anda yakin ingin keluar dari halaman ini tanpa menyimpan perubahan tersebut',
  positiveText: 'Ya, Keluar',
  negativeText: 'Kembali',
};

export const CONFIRM_DELETE_MODAL: ConfirmationModalType = {
  title: 'Konfirmasi Hapus Akun',
  body: 'Tindakan ini akan menghapus akun dari sistem secara permanen. Apakah Anda yakin ingin melanjutkan?',
  positiveText: 'Ya, Hapus',
  negativeText: 'Batal',
};

export const CONFIRM_RECEIVE_DROP_IN_MODAL: ConfirmationModalType = {
  title: 'Konfirmasi Data',
  body: 'Apakah semua input data sudah sesuai?',
  positiveText: 'Konfirmasi Data',
  negativeText: 'Batal',
};

export const NAVIGATE_RECEIVE_DROP_IN_MODAL: ConfirmationModalType = {
  title: 'Data receiving berhasil disimpan',
  body: 'Selamat! Data penerimaan setoran sampah berhasil disimpan. ',
  positiveText: 'Lanjut Sorting',
  negativeText: 'Kembali ke Overview',
};

export const SUCCESS_SORTING: ConfirmationModalType = {
  title: 'Data sorting berhasil disimpan',
  body: 'Selamat! Sampah Sudah Selesai di Sorting',
  positiveText: 'Kembali ke Overview',
  negativeText: '',
};

// Account Management
export const ACCOUNT_STATUS: SelectorOptionType[] = [
  {
    value: 2,
    label: 'Semua',
  },
  {
    value: 1,
    label: 'Active',
  },
  {
    value: 0,
    label: 'Suspended',
  },
];

export const ACTIVE_ACCOUNT_STATUS: number = 1;

export const ACCOUNT_STATUS_LIST: SelectorOptionType[] = [
  {
    value: 1,
    label: 'Active',
  },
  {
    value: 0,
    label: 'Suspended',
  },
];

export const ADMIN_ROLE_ID: number = 1;
export const OPERATIONAL_ROLE_ID: number = 2;
export const SORTER_ROLE_ID: number = 3;
export const OPERATIONAL_ADMIN_ROLE_ID: number = 5;
export const PARTNER_SORTER_ROLE_ID: number = 6;
export const SUPER_ADMIN_ROLE_ID: number = 7;
export const BANNER_PERMITTED_ROLE: number[] = [
  ADMIN_ROLE_ID,
  OPERATIONAL_ROLE_ID,
];

export const DROP_POINTS_PERMITTED_ROLE: number[] = [
  ADMIN_ROLE_ID,
  OPERATIONAL_ROLE_ID,
  SUPER_ADMIN_ROLE_ID,
];

export const DROP_IN_PERMITTED_ROLE: number[] = [
  ADMIN_ROLE_ID,
  OPERATIONAL_ROLE_ID,
  SORTER_ROLE_ID,
  PARTNER_SORTER_ROLE_ID,
  OPERATIONAL_ADMIN_ROLE_ID,
  SUPER_ADMIN_ROLE_ID,
];

export const REBOX_PERMITTED_ROLE: number[] = [
  ADMIN_ROLE_ID,
  SUPER_ADMIN_ROLE_ID,
];

export const WITHDRAW_PERMITTED_ROLE: number[] = [
  ADMIN_ROLE_ID,
  OPERATIONAL_ROLE_ID,
  OPERATIONAL_ADMIN_ROLE_ID,
  SUPER_ADMIN_ROLE_ID,
];

export const FRAUD_PERMITTED_ROLE: number[] = [
  ADMIN_ROLE_ID,
  SUPER_ADMIN_ROLE_ID,
];

export const UCO_PERMITTED_ROLE: number[] = [
  ADMIN_ROLE_ID,
  OPERATIONAL_ROLE_ID,
  OPERATIONAL_ADMIN_ROLE_ID,
  SUPER_ADMIN_ROLE_ID,
];

export const ACCOUNT_MANAGER_PERMITTED_ROLE: number[] = [
  ADMIN_ROLE_ID,
  SUPER_ADMIN_ROLE_ID,
];

export const THRESHOLD_PERMITTED_ROLE: number[] = [SUPER_ADMIN_ROLE_ID];

// Withdraw
export const WITHDRAW_ACTION_DELETE: OptionType = {
  key: 'delete',
  optionText: 'Delete',
};

export const WITHDRAW_ACTION_TRANSFER: OptionType = {
  key: 'transfer',
  optionText: 'Transfer',
};

export const WITHDRAW_LIST_ACTION: OptionType[] = [
  WITHDRAW_ACTION_TRANSFER,
  WITHDRAW_ACTION_DELETE,
];

export const WITHDRAW_STATUS_IN_PROGRESS: SelectorOptionType = {
  value: 1,
  label: 'On Progress',
};

export const WITHDRAW_STATUS_COMPLETED: SelectorOptionType = {
  value: 2,
  label: 'Completed',
};

export const WITHDRAW_STATUS_FAILED: SelectorOptionType = {
  value: 3,
  label: 'Fail',
};

export const WITHDRAW_STATUS_PENDING: SelectorOptionType = {
  value: 4,
  label: 'Pending',
};

export const WITHDRAW_STATUS_TRANSFERING: SelectorOptionType = {
  value: 5,
  label: 'Transfering',
};

export const WITHDRAW_STATUS_ALL: SelectorOptionType = {
  value: 0,
  label: 'All',
};

export const FRAUD_FILTER_GOPAY: SelectorOptionType = {
  value: GOPAY_EXCHANGE_METHOD_ID,
  label: 'Gopay',
};

export const FRAUD_FILTER_BLU: SelectorOptionType = {
  value: BLU_EXCHANGE_METHOD_ID,
  label: 'Blu BCA',
};

export const FRAUD_FILTER_ASTRA: SelectorOptionType = {
  value: ASTRA_EXCHANGE_METHOD_ID,
  label: 'Astrapay',
};

export const WITHDRAW_STATUS_FILTER_GOPAY: SelectorOptionType[] = [
  WITHDRAW_STATUS_ALL,
  WITHDRAW_STATUS_IN_PROGRESS,
  WITHDRAW_STATUS_COMPLETED,
  WITHDRAW_STATUS_PENDING,
  WITHDRAW_STATUS_FAILED,
];

export const WITHDRAW_STATUS_FILTER_BLU: SelectorOptionType[] = [
  WITHDRAW_STATUS_ALL,
  WITHDRAW_STATUS_IN_PROGRESS,
  WITHDRAW_STATUS_COMPLETED,
  WITHDRAW_STATUS_PENDING,
  WITHDRAW_STATUS_FAILED,
];

export const WITHDRAW_STATUS_FILTER_ASTRAPAY: SelectorOptionType[] = [
  WITHDRAW_STATUS_ALL,
  WITHDRAW_STATUS_IN_PROGRESS,
  WITHDRAW_STATUS_TRANSFERING,
  WITHDRAW_STATUS_PENDING,
  WITHDRAW_STATUS_COMPLETED,
  WITHDRAW_STATUS_FAILED,
];

export const WITHDRAW_TABS_GOPAY: SelectorOptionType[] = [
  {
    value: 0,
    label: 'All',
  },
  {
    value: 1,
    label: 'On Progress',
  },
  {
    value: 2,
    label: 'Completed',
  },
  {
    value: 4,
    label: 'Pending',
  },
  {
    value: 3,
    label: 'Failed',
  },
];

export const WITHDRAW_TABS_BLU: SelectorOptionType[] = [
  {
    value: 0,
    label: 'All',
  },
  {
    value: 1,
    label: 'On Progress',
  },
  {
    value: 2,
    label: 'Completed',
  },
  {
    value: 4,
    label: 'Pending',
  },
  {
    value: 3,
    label: 'Failed',
  },
];

export const WITHDRAW_TABS_ASTRAPAY: SelectorOptionType[] = [
  {
    value: 0,
    label: 'All',
  },
  {
    value: 1,
    label: 'On Progress',
  },
  {
    value: 5,
    label: 'Transfering',
  },
  {
    value: 2,
    label: 'Completed',
  },
  {
    value: 4,
    label: 'Pending',
  },
  {
    value: 3,
    label: 'Failed',
  },
];

export const FRAUD_MANAGEMENT_STATUS_FILTER: SelectorOptionType[] = [
  FRAUD_FILTER_GOPAY,
  FRAUD_FILTER_BLU,
  FRAUD_FILTER_ASTRA,
];

export const WITHDRAW_METHOD_ALL: SelectorOptionType = {
  value: 0,
  label: 'All',
};

export const WITHDRAW_METHOD_TRANSACTION_ALL: SelectorOptionType = {
  value: 0,
  label: 'All',
};

export const WITHDRAW_METHOD_TRANSACTION_TRANSFER: SelectorOptionType = {
  value: 1,
  label: 'Transfer',
};

export const WITHDRAW_METHOD_TRANSACTION_TOP_UP: SelectorOptionType = {
  value: 2,
  label: 'Top-Up',
};

export const WITHDRAW_METHOD_TRANSACTION_TYPE: SelectorOptionType[] = [
  WITHDRAW_METHOD_TRANSACTION_ALL,
  WITHDRAW_METHOD_TRANSACTION_TRANSFER,
  WITHDRAW_METHOD_TRANSACTION_TOP_UP,
];

export const WITHDRAW_METHOD_TOP_UP_MODAL: ConfirmationModalType = {
  title: 'Top-up Balance',
  body: 'Enter amount to Top-up',
  positiveText: 'Top-up',
  negativeText: 'Cancel',
};

export const WITHDRAW_METHOD_TOP_UP_EDIT_MODAL: ConfirmationModalType = {
  title: 'Edit Balance',
  body: 'Enter the amount you would like to change',
  positiveText: 'Save change',
  negativeText: 'Cancel',
};

export const WITHDRAW_METHOD_TOP_UP_CONFIRM_MODAL: ConfirmationModalType = {
  title: 'Konfirmasi Top-Up',
  body: 'Apakah anda sudah yakin akan melakukan transaksi Top-Up saldo sebesar Rp.',
  positiveText: 'Ya, Top-up',
  negativeText: 'Kembali',
};

export const WITHDRAW_METHOD_TOP_UP_EDIT_CONFIRM_MODAL: ConfirmationModalType =
  {
    title: 'Konfirmasi Perubahan',
    body: 'Apakah anda sudah yakin akan merubah nilai transaksi Top-Up menjadi Rp.',
    positiveText: 'Ya, Simpan perubahan',
    negativeText: 'Kembali',
  };

export const DELETE_WITHDRAW_CONFIRM_MODAL: ConfirmationModalType = {
  title: 'Konfirmasi Hapus',
  body: 'Tindakan ini akan menghapus akun dari sistem secara permanen. Apakah Anda yakin ingin melanjutkan?',
  positiveText: 'Ya, Hapus',
  negativeText: 'Batal',
};
export const NULL_DATE: string = '0001-01-01T00:00:00Z';

// Drop-in
export const DROP_IN_OVERVIEW_EDIT_ACTION: OptionType = {
  key: 'editData',
  optionText: 'Edit Data',
};

export const DROP_IN_OVERVIEW_DELETE_ACTION: OptionType = {
  key: 'delete',
  optionText: 'Hapus',
};

export const DROP_IN_OVERVIEW_ACTION: OptionType[] = [
  DROP_IN_OVERVIEW_EDIT_ACTION,
  DROP_IN_OVERVIEW_DELETE_ACTION,
];

export const DROP_IN_DELETE_MODAL: ConfirmationModalType = {
  title: 'Konfirmasi Hapus Waste-ID',
  body: 'Tindakan ini akan menghapus Waste-ID dari sistem secara permanen. Apakah Anda yakin ingin melanjutkan?',
  positiveText: 'Ya, Hapus',
  negativeText: 'Batal',
};

export enum ApiMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export enum ApiKind {
  OK = 'ok',
  BAD_DATA = 'bad-data',
}

export const isUco = true;
export const isMultipart = true;

export const EXCEL_TYPE: string =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
