import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Modal } from '../Modal';
import { Button } from '../Button';
import { IMonthPickerModalProps } from './month-picker-modal.props';

export const MonthPickerModal = ({
  show,
  onClose,
  title,
  buttonText = 'Download',
  onButtonPress,
}: IMonthPickerModalProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  return (
    <Modal show={show} onClose={onClose}>
      <h1 className="text-xl font-semibold mb-6">{`${title}`}</h1>
      <div className="flex w-full justify-center border-b-[1px] border-beauBlue-500 pb-6 mb-6">
        <DatePicker
          inline
          showMonthYearPicker
          showFullMonthYearPicker
          selected={selectedDate}
          onChange={e => setSelectedDate(e)}
          maxDate={new Date()}
        />
      </div>
      <div className="flex w-full justify-end">
        <Button
          type={'primary'}
          disabled={!selectedDate}
          onClick={() => {
            if (selectedDate && onButtonPress) onButtonPress(selectedDate);
          }}
        >
          <span>{`${buttonText}`}</span>
        </Button>
      </div>
    </Modal>
  );
};
