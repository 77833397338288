/**
 *
 * Asynchronously loads the component for Banner
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ThresholdSetting = lazyLoad(
  () => import('./threshold-setting'),
  module => module.ThresholdSetting,
);

export const ThresholdForm = lazyLoad(
  () => import('./threshold-form'),
  module => module.ThresholdForm,
);
