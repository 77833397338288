import { createGlobalStyle } from 'styled-components';
import { StyleConstants } from './StyleConstants';
/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    padding-top: ${StyleConstants.NAV_BAR_HEIGHT};
    background-color: ${p => p.theme.background};
  }

  body.fontLoaded {
    font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  p,
  label {
    line-height: 1.5em;
  }

  input, select, button {
    font-family: inherit;
    font-size: inherit;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .bg-warning-tertiary {
    background-color: #FEEFD5
  }

  .bg-warning-primary {
      background-color: #FBAE2E
  }

  .badge-success-primary {
      color: #4D8D01;
      border-color: #F3FFE3;
      background-color:#F3FFE3;
  }

  .badge-error-primary {
      color: #F2021F;
      border-color: #F2021F;
      background-color: white;
  }

  .badge-warning-primary {
      color: #FBAE2E;
      border-color: #FBAE2E;
      background-color: white;
  }

  .bg-error-tertiary {
      background-color: #FFE9EC
  }
  
  .bg-error-primary {
      background-color: #F2021F
  }

  .bg-success-primary {
      background-color: #4D8D01
  }
  
  
`;
