import SearchIcon from '../../../assets/searchIcon.png';
import * as React from 'react';
import { ISearchProps } from 'components/Search/search.props';

export const Search = (props: ISearchProps) => {
  if (!props.show) return <></>;
  return (
    <div className={props.containerStyle ? props.containerStyle : 'p-7 pb-2'}>
      {props.titleAboveSearch ? (
        <div className="py-2">
          <span className="font-medium">{props.titleAboveSearch}</span>
        </div>
      ) : null}
      <form
        className="w-full flex xl:ml-0 mb-4 ring-1 ring-black ring-opacity-20 px-3 py-1 rounded-lg"
        action="#"
        method="GET"
      >
        <label htmlFor="search-field" className="sr-only">
          Search
        </label>
        <div className="relative w-full text-gray-400 focus-within:text-gray-600">
          <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <img className="h-5 w-5" src={SearchIcon} alt="search icon" />
          </div>
          <input
            id="search-field"
            className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
            placeholder="Search"
            type="search"
            name="search"
            value={props.value}
            disabled={!props.searchEnabled ?? true}
            onChange={e => {
              props.onSearchChange &&
                props.onSearchChange(`${e.target.value?.toUpperCase()}`);
            }}
          />
        </div>
      </form>
    </div>
  );
};
