import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectApi } from '../../../../api/slice/selectors';
import { BannerActions as actions } from '../slice';
import { selectBannerForm, selectedBannerId } from './selectors';
import {
  BANNER_DELETE,
  BANNERS,
  GeneralSubmitResult,
} from '../../../../api/api.types';
import { BannerFormType } from '../../../../types/Banner';
import {
  ApiKind,
  ApiMethod,
  isMultipart,
  isUco,
} from '../../../../config/global.config';

function* getBannerList() {
  const api = yield select(selectApi);

  try {
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.GET,
      !isMultipart,
      BANNERS,
      !isUco,
    );
    if (response.kind === ApiKind.OK) {
      const data = response.data;
      yield put(actions.listLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.listError(err));
  }
}

function* deleteBannerById() {
  const bannerId: number = yield select(selectedBannerId);

  const api = yield select(selectApi);

  try {
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.DELETE,
      !isMultipart,
      BANNER_DELETE + bannerId,
      !isUco,
    );
    if (response.kind === ApiKind.OK) {
      const data = response.data;
      yield put(actions.deleteSuccess(data));
    }
  } catch (err: any) {
    yield put(actions.deleteError(err));
  }
}

function* submitBanner() {
  const formData: BannerFormType = yield select(selectBannerForm);
  const api = yield select(selectApi);

  try {
    const formDataUpload = new FormData();
    formDataUpload.append('name', formData.name ?? '');
    formDataUpload.append('sequence', String(formData.sequence ?? '1'));
    formDataUpload.append('action', String(formData.action ?? ''));

    if (formData.fileImage) {
      formDataUpload.append('fileImage', formData.fileImage);
    }

    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.POST,
      isMultipart,
      BANNERS,
      !isUco,
      formDataUpload,
    );

    if (response.kind === ApiKind.OK) {
      const data = response.data;
      yield put(actions.submitSuccess(data));
    } else {
      yield put(
        actions.submitError({
          errors: true,
          data: response.response?.data,
          message: response.response?.message,
          status: response.response?.status,
        }),
      );
    }
  } catch (err: any) {
    yield put(actions.submitError(err));
  }
}

function* updateBanner() {
  const formData: BannerFormType = yield select(selectBannerForm);
  const api = yield select(selectApi);

  try {
    const formDataUpload = new FormData();
    formDataUpload.append('id', String(formData.id ?? ''));
    formDataUpload.append('name', String(formData.name ?? ''));
    formDataUpload.append('sequence', String(formData.sequence ?? '1'));
    formDataUpload.append('action', String(formData.action ?? ''));

    if (formData.fileImage) {
      formDataUpload.append('fileImage', formData.fileImage);
    }

    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.PUT,
      isMultipart,
      BANNERS,
      !isUco,
      formDataUpload,
    );

    if (response.kind === ApiKind.OK) {
      const data = response.data;
      yield put(actions.submitSuccess(data));
    } else {
      yield put(
        actions.submitError({
          errors: true,
          data: response.response?.data,
          message: response.response?.message,
          status: response.response?.status,
        }),
      );
    }
  } catch (err: any) {
    yield put(actions.submitError(err));
  }
}

export function* BannerSaga() {
  yield takeLatest(actions.loadBannerList.type, getBannerList);
  yield takeLatest(actions.loadDeleteBanner.type, deleteBannerById);
  yield takeLatest(actions.submitBanner.type, submitBanner);
  yield takeLatest(actions.submitUpdate.type, updateBanner);
}
