/**
 *
 * Asynchronously loads the component for Banner
 *
 */

import { lazyLoad } from 'utils/loadable';

export const UcoSetting = lazyLoad(
  () => import('./index'),
  module => module.UcoSetting,
);

export const UcoReminderSetting = lazyLoad(
  () => import('./reminder-setting'),
  module => module.UcoReminderSetting,
);
