import { logger } from '../utils/logger';

/**
 * Logger
 */
const log = logger().child({ module: 'ApiMonitor' });

export function responseMonitor(response: any) {
  log.info('🕵️ Api Response Monitor 🕵️');
  log.info('Status :' + response.status);
  log.info('Response :', response);

  const { status } = response;

  if (status === 400) {
    // log.info("Token Expired ❌ " + response.data.message)
    log.info('❌ Error Message : ' + response.data.message);
    // Token Expired thus we forced to log out
    // window.location.assign('/logout');
  }

  if (status === 401) {
    // log.info("Token Expired ❌ " + response.data.message)
    log.info('❌ Un Message : ' + response.data.message);
    // window.location.replace('/');
    // Token Expired thus we forced to log out
    // window.location.assign('/logout');
  }
}
