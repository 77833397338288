/**
 *
 * Asynchronously loads the component for Banner
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Withdraw = lazyLoad(
  () => import('./index'),
  module => module.Withdraw,
);

export const WithdrawMethod = lazyLoad(
  () => import('./withdraw-method'),
  module => module.WithdrawMethod,
);
