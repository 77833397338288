import { call, put, select, takeLatest } from 'redux-saga/effects';
import { DropStationActions as actions } from '.';
import {
  selectDropStationForm,
  selectDropStationId,
  selectDropStationPaginationParams,
} from './selectors';
import { EprPartnerItemType, PaginationParams } from 'types';
import { selectApi } from '../../../../api/slice/selectors';
import {
  convertDropStationType,
  convertOperationalDay,
  formatPost,
  isEmptyList,
  isEmptyString,
} from 'utils';
import { DropStationType } from 'types';
import {
  DROP_STATION,
  DROP_STATION_DELETE,
  EPR_PROGRAM_TYPE,
  DROP_STATION_NEW,
  DROP_STATION_TYPE,
  DROP_STATION_UPDATE_STATUS,
  GeneralSubmitResult,
  VOUCHER_LIST,
  GenericApiResult,
} from '../../../../api/api.types';
import {
  ApiKind,
  ApiMethod,
  isMultipart,
  isUco,
} from '../../../../config/global.config';

function* getVoucherList() {
  const api = yield select(selectApi);

  try {
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.GET,
      !isMultipart,
      VOUCHER_LIST,
      !isUco,
    );

    if (response.kind === ApiKind.OK) {
      const data = response.data.data;
      yield put(actions.voucherLoaded(data));
    }
  } catch (err: any) {
    yield put(actions.voucherLoaded(err));
  }
}

function* getDropStationList() {
  const { page, limit, keyword, dropStationType }: PaginationParams =
    yield select(selectDropStationPaginationParams);
  const api = yield select(selectApi);

  try {
    const params = {
      page,
      limit,
      keyword,
      filter: dropStationType,
    };

    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.GET,
      !isMultipart,
      DROP_STATION,
      !isUco,
      params,
    );

    if (response.kind === ApiKind.OK)
      yield put(actions.listLoaded(response.data.data));
  } catch (err: any) {
    yield put(actions.listError(err));
  }
}

function* getDropStationEPRProgram() {
  const api = yield select(selectApi);

  try {
    const response: GenericApiResult<EprPartnerItemType[]> = yield call(
      [api, 'generalApi'],
      ApiMethod.GET,
      !isMultipart,
      EPR_PROGRAM_TYPE,
      !isUco,
    );

    if (response.kind === ApiKind.OK)
      yield put(actions.eprProgramTypeLoaded(response.data.data));
  } catch (err: any) {
    yield put(actions.eprProgramTypeError(err));
  }
}

function* getDropStationType() {
  const api = yield select(selectApi);

  try {
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.GET,
      !isMultipart,
      DROP_STATION_TYPE,
      !isUco,
    );

    if (response.kind === ApiKind.OK)
      yield put(
        actions.dropStationTypeLoaded(
          convertDropStationType(response.data.data),
        ),
      );
  } catch (err: any) {
    yield put(actions.dropStationTypeError(err));
  }
}

function* getDropStationDetail() {
  const dropStatusId: number = yield select(selectDropStationId);
  const api = yield select(selectApi);

  try {
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.GET,
      !isMultipart,
      DROP_STATION + '/' + dropStatusId,
      !isUco,
    );

    if (response.kind === ApiKind.OK)
      yield put(actions.detailLoaded(response.data.data));
  } catch (err: any) {
    yield put(actions.detailError(err));
  }
}

function* putUpdateStatus() {
  const dropStatusId: number = yield select(selectDropStationId);

  const api = yield select(selectApi);

  try {
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.PUT,
      isMultipart,
      DROP_STATION_UPDATE_STATUS + dropStatusId,
      !isUco,
    );
    if (response.kind === ApiKind.OK) {
      const data = response.data;
      yield put(actions.updateStatusSuccess(data));
    }
  } catch (err: any) {
    yield put(actions.updateStatusError(err));
  }
}

function* deleteDropStation() {
  const dropStatusId: number = yield select(selectDropStationId);
  const api = yield select(selectApi);

  try {
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.DELETE,
      !isMultipart,
      DROP_STATION_DELETE + dropStatusId,
      !isUco,
    );
    if (response.kind === ApiKind.OK)
      yield put(actions.deleteSuccess(response.data));
  } catch (err: any) {
    yield put(actions.deleteError(err));
  }
}

function* updateDropStation() {
  const dropStatusId: number = yield select(selectDropStationId);
  const formData: DropStationType = yield select(selectDropStationForm);
  const api = yield select(selectApi);

  try {
    const formDataUpload = new FormData();
    formDataUpload.append('name', formData.name);
    formDataUpload.append('type', String(formData.type));
    formDataUpload.append('operationalTime', '');
    formDataUpload.append(
      'operationalDay',
      JSON.stringify(convertOperationalDay(formData.operational)),
    );
    formDataUpload.append('longitude', formData.longitude);
    formDataUpload.append('latitude', formData.latitude);
    formDataUpload.append('address', formData.address);
    formDataUpload.append('provinceId', String(formData.provinceId));
    formDataUpload.append('regencyId', String(formData.regencyId));
    formDataUpload.append('districtId', String(formData.districtId));
    formDataUpload.append('villageId', String(formData.villageId));
    formDataUpload.append('rt', formData.rt);
    formDataUpload.append('rw', formData.rw);
    formDataUpload.append('postalCode', formData.postalCode);
    formDataUpload.append('status', String(formData.status));
    formDataUpload.append('disabled', formData.disabled ? '1' : '0');
    formDataUpload.append('installedAt', formatPost(formData.installedAt));
    if (!isEmptyString(formData.keyAccess) && formData.keyAccess)
      formDataUpload.append('password', formData.keyAccess);
    if (!isEmptyString(formData.description) && formData.description)
      formDataUpload.append('description', formData.description);
    if (
      !isEmptyString(formData.collaborationProgramName) &&
      formData.collaborationProgramName
    )
      formDataUpload.append('program', formData.collaborationProgramName);
    if (
      !isEmptyString(formData.collaborationWebViewLink) &&
      formData.collaborationWebViewLink
    )
      formDataUpload.append('url', formData.collaborationWebViewLink);
    if (formData.collaborationBannerFile)
      formDataUpload.append(
        'bannerImage',
        formData.collaborationBannerFile as Blob,
      );
    if (formData.collaborationLogoFile)
      formDataUpload.append(
        'logoImage',
        formData.collaborationLogoFile as Blob,
      );
    if (formData.collaborationPrompt) {
      formData.collaborationPrompt.map(item => {
        formDataUpload.append('prompt', item.prompt);
        if (typeof item.icon === 'string')
          formDataUpload.append('promptIconId', String(item.iconId));
        else {
          formDataUpload.append('promptIconId', String(''));
          // @ts-ignore
          formDataUpload.append('promptIcon', item.icon);
        }
      });
    }
    if (formData.reboxImg)
      formDataUpload.append('fileImage', formData.reboxImg);
    if (formData.hasPassword)
      formDataUpload.append('hasPassword', String(formData.hasPassword));
    if (formData.isEditPassword)
      formDataUpload.append('isEditPassword', String(formData.isEditPassword));

    if (formData.eprPartners?.length) {
      formData.eprPartners.map(value => {
        formDataUpload.append('eprPartnerCode', value.code);
      });
    }

    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.PUT,
      isMultipart,
      DROP_STATION + '/' + dropStatusId,
      !isUco,
      formDataUpload,
    );

    if (response.kind === ApiKind.OK)
      yield put(actions.submitSuccess(response.data));
    else {
      yield put(
        actions.submitError({
          errors: true,
          data: response.response?.data,
          message: response.response?.message,
          status: response.response?.status,
        }),
      );
    }
  } catch (err: any) {
    yield put(actions.submitError(err));
  }
}

function* submitDropStation() {
  const formData: DropStationType = yield select(selectDropStationForm);
  const api = yield select(selectApi);

  try {
    const formDataUpload = new FormData();
    formDataUpload.append('name', formData.name);
    formDataUpload.append('type', String(formData.type));
    formDataUpload.append('operationalTime', '');
    formDataUpload.append(
      'operationalDay',
      JSON.stringify(convertOperationalDay(formData.operational)),
    );
    formDataUpload.append('longitude', formData.longitude);
    formDataUpload.append('latitude', formData.latitude);
    formDataUpload.append('address', formData.address);
    formDataUpload.append('provinceId', String(formData.provinceId));
    formDataUpload.append('regencyId', String(formData.regencyId));
    formDataUpload.append('districtId', String(formData.districtId));
    formDataUpload.append('villageId', String(formData.villageId));
    formDataUpload.append('rt', formData.rt);
    formDataUpload.append('rw', formData.rw);
    formDataUpload.append('postalCode', formData.postalCode);
    formDataUpload.append('status', String(formData.status));
    formDataUpload.append('disabled', formData.disabled ? '1' : '0');
    formDataUpload.append('installedAt', formatPost(formData.installedAt));
    if (!isEmptyString(formData.keyAccess) && formData.keyAccess)
      formDataUpload.append('password', formData.keyAccess);
    if (!isEmptyString(formData.description) && formData.description)
      formDataUpload.append('description', formData.description);
    if (
      !isEmptyString(formData.collaborationProgramName) &&
      formData.collaborationProgramName
    )
      formDataUpload.append('program', formData.collaborationProgramName);
    if (
      !isEmptyString(formData.collaborationWebViewLink) &&
      formData.collaborationWebViewLink
    )
      formDataUpload.append('url', formData.collaborationWebViewLink);
    if (formData.collaborationBannerFile)
      formDataUpload.append(
        'bannerImage',
        formData.collaborationBannerFile as Blob,
      );
    if (formData.collaborationLogoFile)
      formDataUpload.append(
        'logoImage',
        formData.collaborationLogoFile as Blob,
      );
    if (formData.collaborationPrompt) {
      formData.collaborationPrompt.map(item => {
        formDataUpload.append('prompt', item.prompt);
        formDataUpload.append('promptIcon', item.icon as Blob);
      });
    }
    if (formData.reboxImg)
      formDataUpload.append('fileImage', formData.reboxImg as Blob);
    if (formData.hasPassword)
      formDataUpload.append('hasPassword', String(formData.hasPassword));

    if (formData.eprPartners?.length) {
      formData.eprPartners.map(value => {
        formDataUpload.append('eprPartnerCode', value.code);
      });
    }

    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.POST,
      true,
      DROP_STATION,
      !isUco,
      formDataUpload,
    );

    if (response.kind === ApiKind.OK) {
      yield put(actions.submitSuccess(response.data));
    } else {
      yield put(
        actions.submitError({
          errors: true,
          data: response.response?.data,
          message: response.response?.message,
          status: response.response?.status,
        }),
      );
    }
  } catch (err: any) {
    yield put(actions.submitError(err));
  }
}

function* getStationList() {
  const api = yield select(selectApi);

  try {
    const response: GeneralSubmitResult = yield call(
      [api, 'generalApi'],
      ApiMethod.GET,
      !isMultipart,
      DROP_STATION_NEW,
      !isUco,
    );

    if (response.kind === ApiKind.OK)
      yield put(actions.listStationLoaded(response.data.data));
  } catch (err: any) {
    yield put(actions.listStationError(err));
  }
}

export function* DropStationSaga() {
  yield takeLatest(actions.loadVouchers.type, getVoucherList);
  yield takeLatest(actions.loadDropStationList.type, getDropStationList);
  yield takeLatest(actions.loadEPRProgramType.type, getDropStationEPRProgram);
  yield takeLatest(actions.loadDropStationType.type, getDropStationType);
  yield takeLatest(actions.loadDropStationDetail.type, getDropStationDetail);
  yield takeLatest(actions.loadUpdateStatus.type, putUpdateStatus);
  yield takeLatest(actions.loadDeleteDropStation.type, deleteDropStation);
  yield takeLatest(actions.submitDropStation.type, submitDropStation);
  yield takeLatest(actions.submitUpdate.type, updateDropStation);
  yield takeLatest(actions.loadStationList.type, getStationList);
}
