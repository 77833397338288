import { getDownloadURL, ref } from 'firebase/storage';
import { FirebaseStorage, StorageReference } from '@firebase/storage';
import { logger } from 'utils/logger';
import { isUrl } from 'utils/check-string';
import { isEmptyString } from 'utils/account-helper';

/**
 * Logger
 */
const log = logger().child({ module: 'Utils Get Image' });

export const getImageUrl = async (
  imageRef: StorageReference,
): Promise<string> => {
  let response: string = '';
  await getDownloadURL(imageRef)
    .then(url => {
      response = url;
    })
    .catch(e => {
      log.error(e);
      response = '';
    });
  return response;
};

export const getImageBlob = async (url: string) => {
  try {
    return await fetch(url);
  } catch (e) {
    log.error(e);
    return undefined;
  }
};

export const checkImage = async (url: string, storage: FirebaseStorage) => {
  if (isEmptyString(url)) return '';
  if (isUrl(url)) return url;
  else {
    const imageRef = ref(storage, url);
    return await getImageUrl(imageRef);
  }
};
