/**
 *
 * VStack
 *
 */
import * as React from 'react';
import { classNames } from '../../../utils/classNames';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

export function VStack(props: Props) {
  return (
    <div className={classNames('flex flex-row', props.className)}>
      {props.children}
    </div>
  );
}
