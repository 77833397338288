import pino from 'pino';

export function logger() {
  if (process.env.REACT_APP_TYPE !== 'DEVELOPMENT') {
    return pino({
      level: 'silent',
    });
  } else {
    return pino({
      level: 'info',
    });
  }
}
