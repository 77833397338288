import React from 'react';
import { AccountListType } from 'types';
import { acronym } from 'utils';

interface Props {
  profileData: AccountListType;
}

export const AccountMenu = ({ profileData }: Props) => {
  return (
    <div className="flex flex-row px-4 py-2 border-[1px] border-slate-400 rounded-lg justify-center items-center ">
      <div className="flex h-w-xl max-w-xl p-2 bg-black items-center justify-center rounded-full mx-2">
        <span className="text-white text-xl">{acronym(profileData?.name)}</span>
      </div>
      <div className="flex flex-col items-start">
        <span className="font-semibold text-sm text-black">
          {profileData?.name}
        </span>
        <span className="font-medium text-xs text-black">
          {profileData?.roleName}
        </span>
        <span className="font-normal text-xs text-black">
          {profileData?.email}
        </span>
      </div>
    </div>
  );
};
